export const departments = [
	{
		name: 'Amazonas',
		cities: [
			'Leticia',
			'El Encanto',
			'La Chorrera',
			'La Pedrera',
			'La Victoria',
			'Miriti Paraná',
			'Puerto Alegría',
			'Puerto Arica',
			'Puerto Nariño',
			'Puerto Santander',
			'Tarapacá',
		]
	},
	{
		name: 'Antioquia',
		cities: [
			'Medellín',
			'Abejorral',
			'Abriaquí',
			'Alejandría',
			'Amagá',
			'Amalfi',
			'Andes',
			'Angelópolis',
			'Angostura',
			'Anorí',
			'Anza',
			'Apartadó',
			'Arboletes',
			'Argelia',
			'Armenia',
			'Barbosa',
			'Bello',
			'Belmira',
			'Betania',
			'Betulia',
			'Briceño',
			'Buriticá',
			'Cáceres',
			'Caicedo',
			'Caldas',
			'Campamento',
			'Cañasgordas',
			'Caracolí',
			'Caramanta',
			'Carepa',
			'Carolina',
			'Caucasia',
			'Chigorodó',
			'Cisneros',
			'Ciudad Bolívar',
			'Cocorná',
			'Concepción',
			'Concordia',
			'Copacabana',
			'Dabeiba',
			'Don Matías',
			'Ebéjico',
			'El Bagre',
			'El Carmen de Viboral',
			'El Santuario',
			'Entrerrios',
			'Envigado',
			'Fredonia',
			'Frontino',
			'Giraldo',
			'Girardota',
			'Gómez Plata',
			'Granada',
			'Guadalupe',
			'Guarne',
			'Guatapé',
			'Heliconia',
			'Hispania',
			'Itagui',
			'Ituango',
			'Jardín',
			'Jericó',
			'La Ceja',
			'La Estrella',
			'La Pintada',
			'La Unión',
			'Liborina',
			'Maceo',
			'Marinilla',
			'Montebello',
			'Murindó',
			'Mutatá',
			'Nariño',
			'Nechí',
			'Necoclí',
			'Olaya',
			'Peñol',
			'Peque',
			'Pueblorrico',
			'Puerto Berrío',
			'Puerto Nare',
			'Puerto Triunfo',
			'Remedios',
			'Retiro',
			'Rionegro',
			'Sabanalarga',
			'Sabaneta',
			'Salgar',
			'San Andrés de Cuerquía',
			'San Carlos',
			'San Francisco',
			'San Jerónimo',
			'San José de La Montaña',
			'San Juan de Urabá',
			'San Luis',
			'San Pedro',
			'San Pedro de Uraba',
			'San Rafael',
			'San Roque',
			'San Vicente',
			'Santa Bárbara',
			'Santa Rosa de Osos',
			'Santafé de Antioquia',
			'Santo Domingo',
			'Segovia',
			'Sonsón',
			'Sopetrán',
			'Támesis',
			'Tarazá',
			'Tarso',
			'Titiribí',
			'Toledo',
			'Turbo',
			'Uramita',
			'Urrao',
			'Valdivia',
			'Valparaíso',
			'Vegachí',
			'Venecia',
			'Vigía del Fuerte',
			'Yalí',
			'Yarumal',
			'Yolombó',
			'Yondó',
			'Zaragoza',
		]
	},
	{
		name: 'Arauca',
		cities: [
			'Arauca',
			'Arauquita',
			'Cravo Norte',
			'Fortul',
			'Puerto Rondón',
			'Saravena',
			'Tame',
		]
	},
	{
		name: 'Archipiélago de San Andrés',
		cities: [
			'San Andrés',
			'Providencia',
		]
	},
	{
		name: 'Atlántico',
		cities: [
			'Barranquilla',
			'Baranoa',
			'Campo de La Cruz',
			'Candelaria',
			'Galapa',
			'Juan de Acosta',
			'Luruaco',
			'Malambo',
			'Manatí',
			'Palmar de Varela',
			'Piojó',
			'Polonuevo',
			'Ponedera',
			'Puerto Colombia',
			'Repelón',
			'Sabanagrande',
			'Sabanalarga',
			'Santa Lucía',
			'Santo Tomás',
			'Soledad',
			'Suan',
			'Tubará',
			'Usiacurí',
		]
	},
	{
		name: 'DC',
		cities: [
			'Bogotá'
		]
	},
	{
		name: 'Bolívar',
		cities: [
			'Córdoba',
			'Achí',
			'Altos del Rosario',
			'Arenal',
			'Arjona',
			'Arroyohondo',
			'Barranco de Loba',
			'Calamar',
			'Cantagallo',
			'Cartagena',
			'Cicuco',
			'Clemencia',
			'El Carmen de Bolívar',
			'El Guamo',
			'El Peñón',
			'Hatillo de Loba',
			'Magangué',
			'Mahates',
			'Margarita',
			'María la Baja',
			'Mompós',
			'Montecristo',
			'Morales',
			'Norosí',
			'Pinillos',
			'Regidor',
			'Río Viejo',
			'San Cristóbal',
			'San Estanislao',
			'San Fernando',
			'San Jacinto',
			'San Jacinto del Cauca',
			'San Juan Nepomuceno',
			'San Martín de Loba',
			'San Pablo de Borbur',
			'Santa Catalina',
			'Santa Rosa',
			'Santa Rosa del Sur',
			'Simití',
			'Soplaviento',
			'Talaigua Nuevo',
			'Tiquisio',
			'Turbaco',
			'Turbaná',
			'Villanueva',
			'Zambrano',
		]
	},
	{
		name: 'Boyacá',
		cities: [
			'Almeida',
			'Aquitania',
			'Arcabuco',
			'Belén',
			'Berbeo',
			'Betéitiva',
			'Boavita',
			'Boyacá',
			'Briceño',
			'Buena Vista',
			'Busbanzá',
			'Caldas',
			'Campohermoso',
			'Cerinza',
			'Chinavita',
			'Chiquinquirá',
			'Chíquiza',
			'Chiscas',
			'Chita',
			'Chitaraque',
			'Chivatá',
			'Chivor',
			'Ciénega',
			'Cómbita',
			'Coper',
			'Corrales',
			'Covarachía',
			'Cubará',
			'Cucaita',
			'Cuítiva',
			'Duitama',
			'El Cocuy',
			'El Espino',
			'Firavitoba',
			'Floresta',
			'Gachantivá',
			'Gameza',
			'Garagoa',
			'Guacamayas',
			'Guateque',
			'Guayatá',
			'Güicán',
			'Iza',
			'Jenesano',
			'Jericó',
			'La Capilla',
			'La Uvita',
			'La Victoria',
			'Labranzagrande',
			'Macanal',
			'Maripí',
			'Miraflores',
			'Mongua',
			'Monguí',
			'Moniquirá',
			'Motavita',
			'Muzo',
			'Nobsa',
			'Nuevo Colón',
			'Oicatá',
			'Otanche',
			'Pachavita',
			'Páez',
			'Paipa',
			'Pajarito',
			'Panqueba',
			'Pauna',
			'Paya',
			'Paz de Río',
			'Pesca',
			'Pisba',
			'Puerto Boyacá',
			'Quípama',
			'Ramiriquí',
			'Ráquira',
			'Rondón',
			'Saboyá',
			'Sáchica',
			'Samacá',
			'San Eduardo',
			'San José de Pare',
			'San Luis de Gaceno',
			'San Mateo',
			'San Miguel de Sema',
			'San Pablo de Borbur',
			'Santa María',
			'Santa Rosa de Viterbo',
			'Santa Sofía',
			'Santana',
			'Sativanorte',
			'Sativasur',
			'Siachoque',
			'Soatá',
			'Socha',
			'Socotá',
			'Sogamoso',
			'Somondoco',
			'Sora',
			'Soracá',
			'Sotaquirá',
			'Susacón',
			'Sutamarchán',
			'Sutatenza',
			'Tasco',
			'Tenza',
			'Tibaná',
			'Tibasosa',
			'Tinjacá',
			'Tipacoque',
			'Toca',
			'Togüí',
			'Tópaga',
			'Tota',
			'Tunja',
			'Tununguá',
			'Turmequé',
			'Tuta',
			'Tutazá',
			'Umbita',
			'Ventaquemada',
			'Villa de Leyva',
			'Viracachá',
			'Zetaquira',
		]
	},
	{
		name: 'Caldas',
		cities: [
			'Manizales',
			'Aguadas',
			'Anserma',
			'Aranzazu',
			'Belalcázar',
			'Chinchiná',
			'Filadelfia',
			'La Dorada',
			'La Merced',
			'Manzanares',
			'Marmato',
			'Marulanda',
			'Neira',
			'Norcasia',
			'Pácora',
			'Palestina',
			'Pensilvania',
			'Riosucio',
			'Risaralda',
			'Salamina',
			'Samaná',
			'San José',
			'Supía',
			'Victoria',
			'Villamaría',
			'Viterbo',
			'Marquetalia',
		]
	},
	{
		name: 'Caquetá',
		cities: [
			'Florencia',
			'Albania',
			'Curillo',
			'El Doncello',
			'El Paujil',
			'Morelia',
			'Puerto Rico',
			'Solano',
			'Solita',
			'Valparaíso',
			'San José del Fragua',
			'Belén de Los Andaquies',
			'Cartagena del Chairá',
			'Milán',
			'La Montañita',
			'San Vicente del Caguán',
		]
	},
	{
		name: 'Casanare',
		cities: [
			'Yopal',
			'Aguazul',
			'Chámeza',
			'Hato Corozal',
			'La Salina',
			'Monterrey',
			'Pore',
			'Recetor',
			'Sabanalarga',
			'Sácama',
			'Tauramena',
			'Trinidad',
			'Villanueva',
			'San Luis de Gaceno',
			'Paz de Ariporo',
			'Nunchía',
			'Maní',
			'Támara',
			'Orocué',
		]
	},
	{
		name: 'Cauca',
		cities: [
			'Popayán',
			'Almaguer',
			'Argelia',
			'Balboa',
			'Bolívar',
			'Buenos Aires',
			'Cajibío',
			'Caldono',
			'Caloto',
			'Corinto',
			'El Tambo',
			'Florencia',
			'Guachené',
			'Guapi',
			'Inzá',
			'Jambaló',
			'La Sierra',
			'La Vega',
			'López',
			'Mercaderes',
			'Miranda',
			'Morales',
			'Padilla',
			'Patía',
			'Piamonte',
			'Piendamó',
			'Puerto Tejada',
			'Puracé',
			'Rosas',
			'Santa Rosa',
			'Silvia',
			'Sotara',
			'Suárez',
			'Sucre',
			'Timbío',
			'Timbiquí',
			'Toribio',
			'Totoró',
			'Villa Rica',
			'Santander de Quilichao',
			'San Sebastián',
			'Páez',
		]
	},
	{
		name: 'Cesar',
		cities: [
			'Valledupar',
			'Aguachica',
			'Agustín Codazzi',
			'Astrea',
			'Becerril',
			'Bosconia',
			'Chimichagua',
			'Chiriguaná',
			'Curumaní',
			'El Copey',
			'El Paso',
			'Gamarra',
			'González',
			'La Gloria',
			'Manaure',
			'Pailitas',
			'Pelaya',
			'Pueblo Bello',
			'La Paz',
			'San Alberto',
			'San Diego',
			'San Martín',
			'Tamalameque',
			'Río de Oro',
			'La Jagua de Ibirico',
		]
	},
	{
		name: 'Chocó',
		cities: [
			'Istmina',
			'Quibdó',
			'Acandí',
			'Alto Baudo',
			'Atrato',
			'Bagadó',
			'Bahía Solano',
			'Bajo Baudó',
			'Bojaya',
			'Cértegui',
			'Condoto',
			'Juradó',
			'Lloró',
			'Medio Atrato',
			'Medio Baudó',
			'Medio San Juan',
			'Nóvita',
			'Nuquí',
			'Río Iro',
			'Río Quito',
			'Riosucio',
			'Sipí',
			'Unguía',
			'El Litoral del San Juan',
			'El Cantón del San Pablo',
			'El Carmen de Atrato',
			'San José del Palmar',
			'Belén de Bajira',
			'Carmen del Darien',
			'Tadó',
			'Unión Panamericana',
		]
	},
	{
		name: 'Córdoba',
		cities: [
			'San Bernardo del Viento',
			'Montería',
			'Ayapel',
			'Buenavista',
			'Canalete',
			'Cereté',
			'Chimá',
			'Chinú',
			'Cotorra',
			'Lorica',
			'Los Córdobas',
			'Momil',
			'Moñitos',
			'Planeta Rica',
			'Pueblo Nuevo',
			'Puerto Escondido',
			'Purísima',
			'Sahagún',
			'San Andrés Sotavento',
			'San Antero',
			'San Pelayo',
			'Tierralta',
			'Tuchín',
			'Valencia',
			'San José de Uré',
			'Ciénaga de Oro',
			'San Carlos',
			'Montelíbano',
			'La Apartada',
			'Puerto Libertador',
		]
	},
	{
		name: 'Cundinamarca',
		cities: [
			'Anapoima',
			'Arbeláez',
			'Beltrán',
			'Bituima',
			'Bojacá',
			'Cabrera',
			'Cachipay',
			'Cajicá',
			'Caparrapí',
			'Caqueza',
			'Chaguaní',
			'Chipaque',
			'Choachí',
			'Chocontá',
			'Cogua',
			'Cota',
			'Cucunubá',
			'El Colegio',
			'El Rosal',
			'Fomeque',
			'Fosca',
			'Funza',
			'Fúquene',
			'Gachala',
			'Gachancipá',
			'Gachetá',
			'Girardot',
			'Granada',
			'Guachetá',
			'Guaduas',
			'Guasca',
			'Guataquí',
			'Guatavita',
			'Guayabetal',
			'Gutiérrez',
			'Jerusalén',
			'Junín',
			'La Calera',
			'La Mesa',
			'La Palma',
			'La Peña',
			'La Vega',
			'Lenguazaque',
			'Macheta',
			'Madrid',
			'Manta',
			'Medina',
			'Mosquera',
			'Nariño',
			'Nemocón',
			'Nilo',
			'Nimaima',
			'Nocaima',
			'Venecia',
			'Pacho',
			'Paime',
			'Pandi',
			'Paratebueno',
			'Pasca',
			'Puerto Salgar',
			'Pulí',
			'Quebradanegra',
			'Quetame',
			'Quipile',
			'Apulo',
			'Ricaurte',
			'San Bernardo',
			'San Cayetano',
			'San Francisco',
			'Sesquilé',
			'Sibaté',
			'Silvania',
			'Simijaca',
			'Soacha',
			'Subachoque',
			'Suesca',
			'Supatá',
			'Susa',
			'Sutatausa',
			'Tabio',
			'Tausa',
			'Tena',
			'Tenjo',
			'Tibacuy',
			'Tibirita',
			'Tocaima',
			'Tocancipá',
			'Topaipí',
			'Ubalá',
			'Ubaque',
			'Une',
			'Útica',
			'Vianí',
			'Villagómez',
			'Villapinzón',
			'Villeta',
			'Viotá',
			'Zipacón',
			'San Juan de Río Seco',
			'Villa de San Diego de Ubate',
			'Guayabal de Siquima',
			'San Antonio del Tequendama',
			'Agua de Dios',
			'Carmen de Carupa',
			'Vergara',
			'Albán',
			'Anolaima',
			'Chía',
			'El Peñón',
			'Sopó',
			'Gama',
			'Sasaima',
			'Yacopí',
			'Fusagasugá',
			'Zipaquirá',
			'Facatativá',
		]
	},
	{
		name: 'Guainía',
		cities: [
			'Inírida',
			'Barranco Minas',
			'Mapiripana',
			'San Felipe',
			'Puerto Colombia',
			'La Guadalupe',
			'Cacahual',
			'Pana Pana',
			'Morichal',
		]
	},
	{
		name: 'Guaviare',
		cities: [
			'Calamar',
			'San José del Guaviare',
			'Miraflores',
			'El Retorno',
		]
	},
	{
		name: 'Huila',
		cities: [
			'Neiva',
			'Acevedo',
			'Agrado',
			'Aipe',
			'Algeciras',
			'Altamira',
			'Baraya',
			'Campoalegre',
			'Colombia',
			'Elías',
			'Garzón',
			'Gigante',
			'Guadalupe',
			'Hobo',
			'Iquira',
			'Isnos',
			'La Argentina',
			'La Plata',
			'Nátaga',
			'Oporapa',
			'Paicol',
			'Palermo',
			'Palestina',
			'Pital',
			'Pitalito',
			'Rivera',
			'Saladoblanco',
			'Santa María',
			'Suaza',
			'Tarqui',
			'Tesalia',
			'Tello',
			'Teruel',
			'Timaná',
			'Villavieja',
			'Yaguará',
			'San Agustín',
		]
	},
	{
		name: 'La Guajira',
		cities: [
			'Riohacha',
			'Albania',
			'Barrancas',
			'Dibula',
			'Distracción',
			'El Molino',
			'Fonseca',
			'Hatonuevo',
			'Maicao',
			'Manaure',
			'Uribia',
			'Urumita',
			'Villanueva',
			'La Jagua del Pilar',
			'San Juan del Cesar',
		]
	},
	{
		name: 'Magdalena',
		cities: [
			'Santa Marta',
			'Algarrobo',
			'Aracataca',
			'Ariguaní',
			'Cerro San Antonio',
			'Chivolo',
			'Concordia',
			'El Banco',
			'El Piñon',
			'El Retén',
			'Fundación',
			'Guamal',
			'Nueva Granada',
			'Pedraza',
			'Pivijay',
			'Plato',
			'Remolino',
			'Salamina',
			'San Zenón',
			'Santa Ana',
			'Sitionuevo',
			'Tenerife',
			'Zapayán',
			'Zona Bananera',
			'San Sebastián de Buenavista',
			'Sabanas de San Angel',
			'Pijiño del Carmen',
			'Santa Bárbara de Pinto',
			'Pueblo Viejo',
			'Ciénaga',
		]
	},
	{
		name: 'Meta',
		cities: [
			'Uribe',
			'Villavicencio',
			'Acacias',
			'Cabuyaro',
			'Cubarral',
			'Cumaral',
			'El Calvario',
			'El Castillo',
			'El Dorado',
			'Granada',
			'Guamal',
			'Mapiripán',
			'Mesetas',
			'La Macarena',
			'Lejanías',
			'Puerto Concordia',
			'Puerto Gaitán',
			'Puerto López',
			'Puerto Lleras',
			'Puerto Rico',
			'Restrepo',
			'San Juanito',
			'San Martín',
			'Vista Hermosa',
			'Barranca de Upía',
			'Fuente de Oro',
			'San Carlos de Guaroa',
			'San Juan de Arama',
			'Castilla la Nueva',
		]
	},
	{
		name: 'Nariño',
		cities: [
			'Santacruz',
			'Pasto',
			'Albán',
			'Aldana',
			'Ancuyá',
			'Barbacoas',
			'Colón',
			'Consaca',
			'Contadero',
			'Córdoba',
			'Cuaspud',
			'Cumbal',
			'Cumbitara',
			'El Charco',
			'El Peñol',
			'El Rosario',
			'El Tambo',
			'Funes',
			'Guachucal',
			'Guaitarilla',
			'Gualmatán',
			'Iles',
			'Imués',
			'Ipiales',
			'La Cruz',
			'La Florida',
			'La Llanada',
			'La Tola',
			'La Unión',
			'Leiva',
			'Linares',
			'Los Andes',
			'Magüí',
			'Mallama',
			'Mosquera',
			'Nariño',
			'Olaya Herrera',
			'Ospina',
			'Francisco Pizarro',
			'Policarpa',
			'Potosí',
			'Providencia',
			'Puerres',
			'Pupiales',
			'Ricaurte',
			'Roberto Payán',
			'Samaniego',
			'Sandoná',
			'San Bernardo',
			'San Lorenzo',
			'San Pablo',
			'Santa Bárbara',
			'Sapuyes',
			'Taminango',
			'Tangua',
			'Túquerres',
			'Yacuanquer',
			'San Pedro de Cartago',
			'El Tablón de Gómez',
			'Buesaco',
			'San Andrés de Tumaco',
			'Belén',
			'Chachagüí',
			'Arboleda',
		]
	},
	{
		name: 'Norte de Santander',
		cities: [
			'Silos',
			'Cácota',
			'Toledo',
			'Mutiscua',
			'El Zulia',
			'Salazar',
			'Cucutilla',
			'Puerto Santander',
			'Gramalote',
			'El Tarra',
			'Teorama',
			'Arboledas',
			'Lourdes',
			'Bochalema',
			'Convención',
			'Hacarí',
			'Herrán',
			'Tibú',
			'San Cayetano',
			'San Calixto',
			'La Playa',
			'Chinácota',
			'Ragonvalia',
			'La Esperanza',
			'Villa del Rosario',
			'Chitagá',
			'Sardinata',
			'Abrego',
			'Los Patios',
			'Ocaña',
			'Bucarasica',
			'Santiago',
			'Labateca',
			'Cachirá',
			'Villa Caro',
			'Durania',
			'Pamplona',
			'Pamplonita',
			'Cúcuta',
			'El Carmen',
		]
	},
	{
		name: 'Putumayo',
		cities: [
			'Mocoa',
			'Colón',
			'Orito',
			'Puerto Caicedo',
			'Puerto Guzmán',
			'Puerto Leguízamo',
			'Sibundoy',
			'San Francisco',
			'San Miguel',
			'Santiago',
			'Valle de Guamez',
			'Puerto Asís',
			'Villagarzón',
		]
	},
	{
		name: 'Quindío',
		cities: [
			'Armenia',
			'Buenavista',
			'Circasia',
			'Córdoba',
			'Filandia',
			'La Tebaida',
			'Montenegro',
			'Pijao',
			'Quimbaya',
			'Salento',
			'Calarcá',
			'Génova',
		]
	},
	{
		name: 'Risaralda',
		cities: [
			'Pereira',
			'Apía',
			'Balboa',
			'Dosquebradas',
			'Guática',
			'La Celia',
			'La Virginia',
			'Marsella',
			'Mistrató',
			'Pueblo Rico',
			'Quinchía',
			'Santuario',
			'Santa Rosa de Cabal',
			'Belén de Umbría',
		]
	},
	{
		name: 'Santander',
		cities: [
			'Puerto Wilches',
			'Puerto Parra',
			'Bucaramanga',
			'Aguada',
			'Albania',
			'Aratoca',
			'Barbosa',
			'Barichara',
			'Barrancabermeja',
			'Betulia',
			'Bolívar',
			'Cabrera',
			'California',
			'Carcasí',
			'Cepitá',
			'Cerrito',
			'Charalá',
			'Charta',
			'Chipatá',
			'Cimitarra',
			'Concepción',
			'Confines',
			'Contratación',
			'Coromoro',
			'Curití',
			'El Guacamayo',
			'El Playón',
			'Encino',
			'Enciso',
			'Florián',
			'Floridablanca',
			'Galán',
			'Gambita',
			'Girón',
			'Guaca',
			'Guadalupe',
			'Guapotá',
			'Guavatá',
			'Güepsa',
			'Jesús María',
			'Jordán',
			'La Belleza',
			'Landázuri',
			'La Paz',
			'Lebríja',
			'Los Santos',
			'Macaravita',
			'Málaga',
			'Matanza',
			'Mogotes',
			'Molagavita',
			'Ocamonte',
			'Oiba',
			'Onzaga',
			'Palmar',
			'Páramo',
			'Piedecuesta',
			'Pinchote',
			'Puente Nacional',
			'Rionegro',
			'San Andrés',
			'San Gil',
			'San Joaquín',
			'San Miguel',
			'Santa Bárbara',
			'Simacota',
			'Socorro',
			'Suaita',
			'Sucre',
			'Suratá',
			'Tona',
			'Vélez',
			'Vetas',
			'Villanueva',
			'Zapatoca',
			'Palmas del Socorro',
			'San Vicente de Chucurí',
			'San José de Miranda',
			'Santa Helena del Opón',
			'Sabana de Torres',
			'El Carmen de Chucurí',
			'Valle de San José',
			'San Benito',
			'Hato',
			'Chimá',
			'Capitanejo',
			'El Peñón',
		]
	},
	{
		name: 'Sucre',
		cities: [
			'Sincelejo',
			'Buenavista',
			'Caimito',
			'Coloso',
			'Coveñas',
			'Chalán',
			'El Roble',
			'Galeras',
			'Guaranda',
			'La Unión',
			'Los Palmitos',
			'Majagual',
			'Morroa',
			'Ovejas',
			'Palmito',
			'San Benito Abad',
			'San Marcos',
			'San Onofre',
			'San Pedro',
			'Sucre',
			'Tolú Viejo',
			'San Luis de Sincé',
			'San Juan de Betulia',
			'Santiago de Tolú',
			'Sampués',
			'Corozal',
		]
	},
	{
		name: 'Tolima',
		cities: [
			'Alpujarra',
			'Alvarado',
			'Ambalema',
			'Armero',
			'Ataco',
			'Cajamarca',
			'Chaparral',
			'Coello',
			'Coyaima',
			'Cunday',
			'Dolores',
			'Espinal',
			'Falan',
			'Flandes',
			'Fresno',
			'Guamo',
			'Herveo',
			'Honda',
			'Icononzo',
			'Mariquita',
			'Melgar',
			'Murillo',
			'Natagaima',
			'Ortega',
			'Palocabildo',
			'Piedras',
			'Planadas',
			'Prado',
			'Purificación',
			'Rio Blanco',
			'Roncesvalles',
			'Rovira',
			'Saldaña',
			'Santa Isabel',
			'Venadillo',
			'Villahermosa',
			'Villarrica',
			'Valle de San Juan',
			'Carmen de Apicala',
			'San Luis',
			'San Antonio',
			'Casabianca',
			'Anzoátegui',
			'Ibagué',
			'Líbano',
			'Lérida',
			'Suárez',
		]
	},
	{
		name: 'Valle del Cauca',
		cities: [
			'El Dovio',
			'Roldanillo',
			'Argelia',
			'Sevilla',
			'Zarzal',
			'El Cerrito',
			'Cartago',
			'Caicedonia',
			'El Cairo',
			'La Unión',
			'Restrepo',
			'Dagua',
			'Guacarí',
			'Ansermanuevo',
			'Bugalagrande',
			'La Victoria',
			'Ginebra',
			'Yumbo',
			'Obando',
			'Bolívar',
			'Cali',
			'San Pedro',
			'Guadalajara de Buga',
			'Calima',
			'Andalucía',
			'Pradera',
			'Yotoco',
			'Palmira',
			'Riofrío',
			'Alcalá',
			'Versalles',
			'El Águila',
			'Toro',
			'Candelaria',
			'La Cumbre',
			'Ulloa',
			'Trujillo',
			'Vijes',
			'Tuluá',
			'Florida',
			'Jamundí',
			'Buenaventura',
		]
	},
	{
		name: 'Vaupés',
		cities: [
			'Carurú',
			'Mitú',
			'Pacoa',
			'Papunaua',
			'Taraira',
			'Yavaraté',
		],
	},
	{
		name: 'Vichada',
		cities: [
			'Puerto Carreño',
			'La Primavera',
			'Santa Rosalía',
			'Cumaribo',
		]
	}
]