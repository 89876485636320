import dewarApi, { getDomain } from '../../services/dewarApi'

export const getReviewSpecs = params => (
	new Promise((resolve, reject) => {
		dewarApi()
			.get('/review_specs', { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const getReviewSpec = (id, params) => (
	new Promise((resolve, reject) => {
		dewarApi()
			.get(`/review_specs/${id}`, { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const storeReviewSpec = data => (
	new Promise((resolve, reject) => {
		dewarApi()
			.post('/review_specs', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const updateReviewSpec = (id, data) => (
	new Promise((resolve, reject) => {
		dewarApi()
			.put(`/review_specs/${id}`, data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const deleteReviewSpec = id => (
	new Promise((resolve, reject) => {
		dewarApi()
			.delete(`/review_specs/${id}`)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const searchReviewSpecs = async (name, token) => (
	fetch( getDomain()+`/review_specs?filter[name]=${name}`, {
		headers: {'Authorization': `Bearer ${token}`}
	})
		.then((response) => response.json())
		.then((res) =>
			res.data.map(service_review => ({
				label: service_review.name,
				value: service_review.id,
			})),
		)
)