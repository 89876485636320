import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { Form, Spinner } from 'react-bootstrap'
import { message } from 'antd'

import IslandSpecForm from './IslandSpecForm'

import { offlineUpdateReview } from '../../Services/actions'
import { queueRequest } from '../../Offline/actions'

import { storeReviewSpec } from '../services'
import { handleError } from '../../../helpers'

export default function NewIslandSpecModal({ visible, onClose, reload, serviceId, serviceReview }) {
	const { isConnected } = useSelector(state => state.offline)
	const { register, handleSubmit, formState: { errors } } = useForm()
	const [loading, setLoading] = useState(false)
	const dispatch = useDispatch()

	const onSubmit = values => {
		setLoading(true)
		if(isConnected){
			storeReviewSpec({ ...values, service_review_id: serviceReview.id })
				.then(res => {
					message.success(res.data.message)
					onClose()
					reload()
				})
				.catch(error => handleError(error, setLoading))
		} else {
			dispatch(offlineUpdateReview({
				...serviceReview,
				island_specs: [
					...serviceReview.island_specs,
					{ ...values }
				]
			}, serviceId))
			dispatch(queueRequest({ method:'POST', url:'/review_specs', data: { ...values, service_review_id: serviceReview.id } }))
			message.info('Actualización en espera de internet')
			setLoading(false)
			onClose()
			reload()
		}
	}

	return (
		<React.Fragment>
			<Modal isOpen={visible}>
				<ModalHeader toggle={onClose}>Agregar Características de la Instalación</ModalHeader>
				<ModalBody>
					<Form onSubmit={handleSubmit(onSubmit)}>
						<IslandSpecForm register={register} errors={errors} />
						<Button color='primary' type="submit" disabled={loading}>
							{ loading && <Spinner animation='border' size="sm" className='mr-5' /> }
							Agregar
						</Button>
					</Form>
				</ModalBody>
			</Modal>
		</React.Fragment>
	)
}