import React, { useState, useEffect } from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'

import EmailLogsTable from './partials/EmailLogsTable'

import { handleError, parsePagination } from '../../helpers'
import { getEmailLogs } from './services'

export default function EmailLogsModal({ visible, onClose, modelType, modelId }) {
	const [emailLogs, setEmailLogs] = useState(null)
	const [pagination, setPagination] = useState({page: 1, per_page: 15, total: 0})

	useEffect(() => {
		!emailLogs && getEmailLogs({
			...pagination,
			include: 'service.proposal.inquiry.branchSpec.branch.company',
			sort: '-created_at',
			'filter[model_type]': modelType,
			'filter[model_id]': modelId,
		})
			.then(res => {
				setEmailLogs(res.data.data)
				setPagination(parsePagination(res))
			})
			.catch(error => handleError(error))
	}, [emailLogs, pagination, modelType, modelId])

	return (
		<React.Fragment>
			<Modal isOpen={visible} toggle={onClose} size="xl">
				<ModalHeader toggle={onClose}>Histórico de Correos Enviados</ModalHeader>
				<ModalBody>
					<EmailLogsTable
						emailLogs={emailLogs}
						reload={() => setEmailLogs(null)}
						pagination={pagination}
						setPagination={setPagination}
					/>
					<small>Solo aplica para correos enviados a partir del 15 Octubre 2022</small>
				</ModalBody>
			</Modal>
		</React.Fragment>
	)
}