import React, { useState } from 'react'
import { Table, Descriptions } from 'antd'
import { Button } from 'reactstrap'

import RegisterPendingTaskModal from './RegisterPendingTaskModal'

const { Item } = Descriptions

export default function PendingTasksTable({ 
	taskTemplates, verificationList, instalationTypeId, serviceReviewId, reload, editable, serviceId
}) {
	const [registerModal, setRegisterModal] = useState(null)
	
	const columns = [
		{
			title: 'Item',
			dataIndex: 'sku',
		},
		{
			title: 'Descripción',
			dataIndex: 'legal_description',
		},
		{
			title: 'Alcance',
			dataIndex: 'instalation_types',
			render: types => {
				let type = types.filter(t => t.id === instalationTypeId)[0]
				return type.pivot.scope
			}
		},
		{
			title: 'Acciones',
			render: (t, r) => (
				<React.Fragment>
					<Button
						size="sm"
						color="primary"
						outline
						onClick={() => setRegisterModal(r)}
						disabled={!editable}
					>
						Registrar
					</Button>
				</React.Fragment>
			)
		}
	]

	const expandedRowRender = record => (
		<Descriptions bordered size="small" column={1}>
			<Item label="Artículo">{record.article}</Item>
			<Item label="Aplicación para Instalaciones">{record.instalation_period}</Item>
			<Item label="Plazo">{record.description}</Item>
			<Item label="Tipo de Tarea">{record.task_type}</Item>
			<Item label="Característica Minminas">{record.minminas_criteria}</Item>
			<Item label="Evidencia Minminas">{record.general_criteria}</Item>
		</Descriptions>
	)
	
	return (
		<React.Fragment>
			<Table 
				dataSource={taskTemplates}
				rowKey={r => r.id}
				loading={!taskTemplates}
				size="small"
				columns={columns}
				expandable={{ expandedRowRender }}
			/>
			{ registerModal && (
				<RegisterPendingTaskModal 
					visible
					onClose={() => setRegisterModal(null)}
					verificationList={verificationList}
					taskTemplate={registerModal}
					serviceReviewId={serviceReviewId}
					reload={reload}
					serviceId={serviceId}
				/>
			)}
		</React.Fragment>
	)
}