import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Form } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { message, Descriptions } from 'antd'

import VerificationTaskForm from './VerificationTaskForm'

import { updateVerificationTask } from '../services'
import { handleError } from '../../../helpers'
import { offlineUpdateTasks } from '../../Services/actions'
import { queueRequest } from '../../Offline/actions'

const { Item } = Descriptions

export default function EditVerificationTaskModal({ 
	visible, onClose, verificationTask, reload, isRetry, serviceId 
}) {
	const { isConnected } = useSelector(state => state.offline)
	const { register, handleSubmit, formState: { errors }, setValue } = useForm()
	const taskTemplate = verificationTask.task_template
	const dispatch = useDispatch()

	const onSubmit = values => {
		let data = {
			is_active: values.status !== 'NA' ? 1 : 0,
			is_compliant: values.status === 'C' ? 1 : 0,
			is_require: (values.status === 'C' || values.status === 'NC') ? 1 : 0,
		}
		if(isRetry) data.observations_retry = values.observations
		else data.observations = values.observations

		data.initially_required = verificationTask.is_require

		if(isConnected){
			updateVerificationTask(verificationTask.id, data)
				.then(res => {
					message.success(res.data.message)
					onClose()
					reload()
				})
				.catch(error => handleError(error))
		} else {
			dispatch(offlineUpdateTasks({ ...verificationTask, ...data }, serviceId))
			dispatch(queueRequest({ url:`/verification_tasks/${verificationTask.id}`, data }))
			message.info('Actualización en espera de internet')
			onClose()
			reload()
		}
	}

	return (
		<React.Fragment>
			<Modal isOpen={visible}>
				<ModalHeader toggle={onClose}>Actualizar Tarea de Verificación</ModalHeader>
				<ModalBody>
					<Form onSubmit={handleSubmit(onSubmit)}>
						<VerificationTaskForm 
							register={register} 
							errors={errors} 
							verificationTask={verificationTask}
							setValue={setValue}
							taskTemplate={taskTemplate}
							isRetry={isRetry}
						/>
						<Button color="primary" type="submit">Actualizar Tarea</Button>
					</Form>
					<Descriptions bordered size="small" column={1} className='mt-20'>
						<Item label="Artículo">{taskTemplate.article}</Item>
						<Item label="Descripción">{taskTemplate.legal_description}</Item>
						<Item label="Plazo">{taskTemplate.description}</Item>
						<Item label="Aplicación para Instalaciones">{taskTemplate.instalation_period}</Item>
						<Item label="Características Minminas">{taskTemplate.minminas_criteria}</Item>
						<Item label="Evidencia Minminas">{taskTemplate.general_criteria}</Item>
					</Descriptions>
				</ModalBody>
			</Modal>
		</React.Fragment>
	)
}