import React from 'react'
import { Redirect, Route, Switch } from 'react-router'

import PreInvoicePreview from './PreInvoicePreview'

export default function PreInvoices() {
	return (
		<React.Fragment>
			<Switch>
				<Route exact path="/pre_invoices/preview/:id" component={PreInvoicePreview} />
				
				<Redirect to="/404" />
			</Switch>
		</React.Fragment>
	)
}