import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Card, CardBody } from 'reactstrap'

import ListTopBar from '../../components/ListTopBar'
import CompaniesTable from './partials/CompaniesTable'
import NewCompanyModal from './partials/NewCompanyModal'
import InternetRequired from '../Offline/partials/InternetRequired'

import { getCompanies } from './services'
import { handleError, parsePagination } from '../../helpers'

export default function CompaniesList() {
	const { isConnected } = useSelector(state => state.offline)
	const [companies, setCompanies] = useState(null)
	const [filterType, setFilterType] = useState('name')
	const [filterValue, setFilterValue] = useState('')
	const [filterScope, setFilterScope] = useState('')
	const [showNewModal, setShowNewModal] = useState(false)
	const [pagination, setPagination] = useState({page: 1, per_page: 15, total: 0})

	useEffect(() => {
		!companies && isConnected && getCompanies({
			...pagination,
			[`filter[${filterType}]`]: filterValue,
			[filterScope ? `filter[${filterScope}]` : 'noscope']: 1,
		})
			.then(res => {
				setCompanies(res.data.data)
				setPagination(parsePagination(res))
			})
			.catch(error => handleError(error))
	}, [companies, filterType, filterValue, isConnected, pagination, filterScope])

	if(!isConnected) return <InternetRequired />

	return (
		<React.Fragment>
			<ListTopBar 
				filterType={filterType}
				setFilterType={setFilterType}
				filterValue={filterValue}
				setFilterValue={setFilterValue}
				title="Directorio de Empresas"
				subtitle="Registradas como clientes de DEWAR"
				reload={() => setCompanies(null)}
				options={[
					{ label: 'Buscar por nombre', value: 'name' },
					{ label: 'Buscar por documento', value: 'document' },
					{ label: 'Buscar por email', value: 'email' },
					{ label: 'Buscar por celular', value: 'mobile' },
				]}
				ctaText="Agregar Empresa"
				handleCtaClick={() => setShowNewModal(true)}
				filterScope={filterScope}
				setFilterScope={setFilterScope}
				scopes={[
					{ label: 'Empresas con acceso al portal', value: 'accessGranted' },
					{ label: 'Empresas sin acceso al portal', value: 'accessUngranted' },
				]}
			/>
			<Card>
				<CardBody>
					<CompaniesTable 
						companies={companies}
						reload={() => setCompanies(null)}
						pagination={pagination}
						setPagination={setPagination}
					/>
				</CardBody>
			</Card>

			{ showNewModal && (
				<NewCompanyModal 
					visible
					onClose={() => setShowNewModal(false)}
					reload={() => setCompanies(null)}
				/>
			)}
			
		</React.Fragment>
	)
}