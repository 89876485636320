import dewarApi, { getDomain } from '../../services/dewarApi'

export const getCertificates = params => (
	new Promise((resolve, reject) => {
		dewarApi()
			.get('/certificates', { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const getCertificate = (id, params) => (
	new Promise((resolve, reject) => {
		dewarApi()
			.get(`/certificates/${id}`, { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const publicGetCertificate = (id, params) => (
	new Promise((resolve, reject) => {
		dewarApi()
			.get(`/public/certificates/${id}`, { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const storeCertificate = data => (
	new Promise((resolve, reject) => {
		dewarApi()
			.post('/certificates', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const updateCertificate = (id, data) => (
	new Promise((resolve, reject) => {
		dewarApi()
			.put(`/certificates/${id}`, data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const deleteCertificate = id => (
	new Promise((resolve, reject) => {
		dewarApi()
			.delete(`/certificates/${id}`)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const searchCertificates = async (name, token) => (
	fetch( getDomain()+`/certificates?filter[name]=${name}`, {
		headers: {'Authorization': `Bearer ${token}`}
	})
		.then((response) => response.json())
		.then((res) =>
			res.data.map(certificate => ({
				label: certificate.name,
				value: certificate.id,
			})),
		)
)

export const notifyCertificate = id => (
	new Promise((resolve, reject) => {
		dewarApi()
			.post(`/certificates/${id}/notify`)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)