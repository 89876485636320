import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { faUsers, faStore, faFileImage, faCheckSquare, faQrcode } from '@fortawesome/free-solid-svg-icons'

import PageTitle from '../../components/PageTitle'
import MenuWidget from '../../components/MenuWidget'

export default function SettingsDashboard() {
	return (
		<React.Fragment>
			<PageTitle title="Ajustes Generales" subtitle="Administrar usuarios, tiendas y permisos." />
			<Row>
				<Col sm="12" md="6" lg="4" className="mb-20">
					<MenuWidget 
						title="Directorio de Usuarios"
						subtitle="Consultar, crear y actualizar usuarios de la plataforma"
						icon={faUsers}
						linkTo={"/settings/users"}
					/>
				</Col>
				<Col sm="12" md="6" lg="4" className="mb-20">
					<MenuWidget
						title="Tipos de Instalación"
						subtitle="Consultar, crear y editar tipos de instalación"
						icon={faStore}
						linkTo={"/settings/instalation_types"}
					/>
				</Col>
				<Col sm="12" md="6" lg="4" className="mb-20">
					<MenuWidget
						title="Lista de Verificación Maestra"
						subtitle="Administrar plantillas de tareas"
						icon={faCheckSquare}
						linkTo={"/settings/task_templates"}
					/>
				</Col>
				<Col sm="12" md="6" lg="4" className="mb-20">
					<MenuWidget
						title="Documentos y Fotográfias"
						subtitle="Administrar plantillas de evidencia"
						icon={faFileImage}
						linkTo={"/settings/evidence_templates"}
					/>
				</Col>
				<Col sm="12" md="6" lg="4" className="mb-20">
					<MenuWidget
						title="Versiones de Formatos"
						subtitle="Administrar versiones de formatos"
						icon={faQrcode}
						linkTo={"/settings/format_versions"}
					/>
				</Col>
			</Row>
		</React.Fragment>
	)
}