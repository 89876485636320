import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Table, Tooltip, Divider, Popconfirm, message, Empty, Descriptions } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faFileImage, faStore, faToggleOn } from '@fortawesome/free-solid-svg-icons'

import TaskInstalationTypesModal from './TaskInstalationTypesModal'

import { deleteTaskTemplate } from '../services'
import { handleError } from '../../../helpers'
import TaskEvidenceTemplatesModal from './TaskEvidenceTemplatesModal'
import EditTaskTemplateModal from './EditTaskTemplateModal'

const { Item } = Descriptions

export default function TaskTemplatesTable({ taskTemplates, reload }) {
	const currentUser = useSelector(state => state.auth.user)
	const [instalationTypesModal, setInstalationTypesModal] = useState(null)
	const [evidenceTemplatesModal, setEvidenceTemplatesModal] = useState(null)
	const [editModal, setEditModal] = useState(null)
	
	const columns = [
		{
			title: 'Ítem',
			dataIndex: 'sku',
		},
		{
			title: 'Tipo',
			dataIndex: 'type',
		},
		{
			title: 'Descripción',
			dataIndex: 'legal_description'
		},
		{
			title: 'Acciones',
			width: '160px',
			render: (t, r) => (
				<React.Fragment>
					<Tooltip title="Tipos de Instalación">
						<FontAwesomeIcon 
							icon={faStore} 
							className='text-link' 
							onClick={() => setInstalationTypesModal(r)}
						/>
					</Tooltip>
					<Divider type="vertical" />
					<Tooltip title="Evidencia Relacionada">
						<FontAwesomeIcon 
							icon={faFileImage} 
							className='text-link' 
							onClick={() => setEvidenceTemplatesModal(r)}
						/>
					</Tooltip>
					{ currentUser.role === 'admin' && (
						<React.Fragment>
							<Divider type="vertical" />
							<Tooltip title="Editar">
								<FontAwesomeIcon
									icon={faEdit}
									className="text-link"
									onClick={() => setEditModal(r)}
								/>
							</Tooltip>
							<Divider type="vertical" />
							<Tooltip title="Desactivar">
								<Popconfirm 
									title="¿Desea desactivar la plantilla?"
									okText="Desactivar"
									cancelText="Cancelar"
									onConfirm={() => handleDelete(r.id)}
									okButtonProps={{ danger:true }}
								>
									<FontAwesomeIcon className="text-link" icon={faToggleOn} />
								</Popconfirm>
							</Tooltip>
						</React.Fragment>
					)}
				</React.Fragment>
			)
		}
	]

	const handleDelete = id => {
		deleteTaskTemplate(id)
			.then(res => {
				message.success(res.data.message)
				reload()
			})
			.catch(error => handleError(error))
	}

	const expandedRowRender = record => (
		<Descriptions bordered size="small" column={1}>
			<Item label="Artículo">{record.article}</Item>
			<Item label="Aplicación para Instalaciones">{record.instalation_period}</Item>
			<Item label="Plazo">{record.description}</Item>
			<Item label="Característica Minminas">{record.minminas_criteria}</Item>
			<Item label="Evidencia Mminas">{record.general_criteria}</Item>
			<Item label="Comentario Inspector Cuando es Conforme">{record.resolved_placeholder}</Item>
			<Item label="Comentario Inspector Cuando es No Conforme">{record.no_resolved_placeholder}</Item>
		</Descriptions>
	)

	return (
		<React.Fragment>
			<Table 
				dataSource={taskTemplates}
				columns={columns}
				rowKey={r => r.id}
				className='mt-30'
				size="small"
				locale={{ emptyText: <Empty description="No tiene plantillas creadas" imageStyle={{ height: '70px' }} /> }}
				loading={!taskTemplates}
				expandable={{ expandedRowRender }}
			/>
			{ instalationTypesModal && (
				<TaskInstalationTypesModal 
					visible
					onClose={() => setInstalationTypesModal(null)}
					taskTemplate={instalationTypesModal}
					reload={newRecord => {
						reload()
						setInstalationTypesModal(newRecord)
					}}
				/>
			)}
			{ evidenceTemplatesModal && (
				<TaskEvidenceTemplatesModal 
					visible
					onClose={() => setEvidenceTemplatesModal(null)}
					taskTemplate={evidenceTemplatesModal}
					reload={newRecord => {
						reload()
						setEvidenceTemplatesModal(newRecord)
					}}
				/>
			)}
			{ editModal && (
				<EditTaskTemplateModal 
					visible
					onClose={() => setEditModal(null)}
					taskTemplate={editModal}
					reload={reload}
				/>
			)}
		</React.Fragment>
	)
}