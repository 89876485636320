import React from 'react'
import { Redirect, Route, Switch } from 'react-router'

import ServiceNotificationPreview from './ServiceNotificationPreview'

export default function ServiceNotifications() {
	return (
		<React.Fragment>
			<Switch>
				<Route exact path="/service_notifications/preview/:id" component={ServiceNotificationPreview} />

				<Redirect to="/404" />
			</Switch>
		</React.Fragment>
	)
}