import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { Form, Spinner } from 'react-bootstrap'
import { message } from 'antd'

import TankForm from './TankForm'

import { storeTank } from '../services'
import { handleError } from '../../../helpers'

export default function NewTankModal({ visible, onClose, reload, branchSpecId }) {
	const { register, handleSubmit, formState: { errors }, reset } = useForm()
	const [loading, setLoading] = useState(false)

	const onSubmit = values => {
		setLoading(true)
		values.branch_spec_id = branchSpecId
		storeTank(values)
			.then(res => {
				message.success(res.data.message)
				reset()
				onClose()
				reload()
			})
			.catch(error => handleError(error, setLoading))
	}

	return (
		<React.Fragment>
			<Modal isOpen={visible} size="lg">
				<ModalHeader toggle={onClose}>Agregar Tanque</ModalHeader>
				<ModalBody>
					<Form onSubmit={handleSubmit(onSubmit)}>
						<TankForm register={register} errors={errors} />
						<Button color="primary" type="submit" disabled={loading}>
							{ loading && <Spinner animation='border' size="sm" className='mr-5' /> }
							Agregar
						</Button>
					</Form>
				</ModalBody>
			</Modal>
		</React.Fragment>
	)
}