import React from 'react'
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import styled from 'styled-components'

import { logout } from '../../containers/Authentication/services'
import { userLogout } from '../../containers/Authentication/actions'
import { handleError } from '../../helpers'

export default function MainMenu({ closeMenu }) {
	const user = useSelector(state => state.auth.user)
	const dispatch = useDispatch()

	const onLogout = () => {
		logout()
			.then(() => dispatch(userLogout()))
			.catch(error => handleError(error))
	}

	return (
		<React.Fragment>
			<Container>
				<UserContainer>
					<UserName>{user.name}</UserName>
					<p>c.c. {user.document}</p>
					<p className="mb-0 text-link" onClick={onLogout}>Cerrar Sesión</p>
				</UserContainer>
				<MenuList>
					<li><Link to="/inquiries" onClick={closeMenu}>Solicitudes de Servicio</Link></li>
					{ user.role === 'admin' && (
						<li><Link to="/proposals" onClick={closeMenu}>Propuestas Comerciales</Link></li>
					)}
					{ user.role !== 'director_pruebas' && user.role !== 'inspector_pruebas' && (
						<li><Link to="/services/inspeccion" onClick={closeMenu}>Servicios de Inspección</Link></li>
					)}
					{ user.role !== 'director_instalaciones' && user.role !== 'inspector' && (
						<li><Link to="/services/pruebas" onClick={closeMenu}>Servicios de Pruebas</Link></li>
					)}
					<li><Link to="/service_reports" onClick={closeMenu}>Informes de Servicio</Link></li>
					<li><Link to="/tickets" onClick={closeMenu}>Novedades Reportadas</Link></li>
					{ (user.role === 'admin' || user.role.includes('director')) && (
						<React.Fragment>
							<li><Link to="/companies" onClick={closeMenu}>Directorio de Clientes</Link></li>
							<li><Link to="/branches" onClick={closeMenu}>Directorio de Instalaciones</Link></li>
							<li><Link to="/certificates" onClick={closeMenu}>Certificados Otorgados</Link></li>
						</React.Fragment>
					)}
					{ user.role === 'admin' && (
						<React.Fragment>
							<li><Link to="/reports" onClick={closeMenu}>Reportes Administrador</Link></li>
							<li><Link to="/email_logs" onClick={closeMenu}>Estado de Correos Enviados</Link></li>
							<li><Link to="/sync_logs" onClick={closeMenu}>Sincronizaciones Fallidas</Link></li>
							<li><Link to="/settings" onClick={closeMenu}>Ajustes del Sistema</Link></li>
						</React.Fragment>
					)}
				</MenuList>
				<p>Correo de soporte: soporte@smart4.com.co</p>
			</Container>
		</React.Fragment>
	)
}

const Container = styled.div`
	position: fixed;
	top: 0px;
	left: 0px;
	height: 100vh;
	z-index: 9;	
	padding-top: 50px;
	width: 240px;
	background-color: white;
	text-align: center;
	-webkit-box-shadow: 3px 0 12px 0 #b3b3b3;
	box-shadow: 3px 0 12px 0 #b3b3b3;
	overflow-y: scroll;
`

const UserContainer = styled.div`
	padding: 40px 20px 30px;
	background-color: #e8e8e8;
`

const UserName = styled.h6`
	font-size: 17px;
	text-transform: uppercase;
	font-weight: 600;
`

const MenuList = styled.ul`
	list-style: none;
	margin: 0px 0px 30px;
	padding: 0;
	& li {
		padding: 0;
    	margin: 0;
	}
	& li a {
		display: inline-block;
		padding: 14px 0;
		margin: 0;
		border-bottom: 1px solid #e8e8e8;
		width: 100%;
	}
	& li a:hover {
		cursor: pointer;
		background-color: #333;
		color: #fff !important;
	}
`