import dewarApi from '../../services/dewarApi'

export const getTicketNotes = params => (
	new Promise((resolve, reject) => {
		dewarApi()
			.get('/ticket_notes', { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const getTicketNote = (id, params) => (
	new Promise((resolve, reject) => {
		dewarApi()
			.get(`/ticket_notes/${id}`, { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const storeTicketNote = data => (
	new Promise((resolve, reject) => {
		dewarApi()
			.post('/ticket_notes', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const updateTicketNote = (id, data) => (
	new Promise((resolve, reject) => {
		dewarApi()
			.put(`/ticket_notes/${id}`, data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const deleteTicketNote = id => (
	new Promise((resolve, reject) => {
		dewarApi()
			.delete(`/ticket_notes/${id}`)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)