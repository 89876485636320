import React from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import { Divider, Empty } from 'antd'
import moment from 'moment'

import NewTicketNoteForm from './NewTicketNoteForm'

export default function TicketNotesModal({ visible, onClose, ticket, reload }) {
	return (
		<Modal isOpen={visible}>
			<ModalHeader toggle={onClose}>Comentarios Ticket #{ticket.id}</ModalHeader>
			<ModalBody>
				<p className='mb-5'>
					<strong>Motivo del solicitud:</strong> {ticket.ticket_type.category_name}
				</p>
				<p className='mb-10'>
					<strong>Tipo de solicitud:</strong> {ticket.ticket_type.name}
				</p>
				<div className='bg-light p-10'>
					{ ticket.ticket_notes.length > 0 ? (
						<React.Fragment>
							{ ticket.ticket_notes.map(ticketNote => (
								<div key={ticketNote.id} className='p-10 bg-white mb-10'>
									<p className='text-muted small mb-5'>{ ticketNote.author_name }</p>
									<p className='mb-5'>{ticketNote.description}</p>
									<p className='text-muted small mb-0'>{ moment(ticketNote.created_at).format('YYYY-MM-DD hh:mm A') }</p>
								</div>
							)) }
						</React.Fragment>
					) : (
						<Empty 
							description="Sin comentarios asociados" 
							imageStyle={{ height: '80px' }}
						/>
					)}
				</div>
				<Divider className='my-10' />
				<NewTicketNoteForm 
					ticket={ticket}
					reload={() => {
						reload()
						onClose()
					}}
				/>
			</ModalBody>
		</Modal>
	)
}