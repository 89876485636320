import React from 'react'
import { Row, Col, InputGroup, Form } from 'react-bootstrap'

import { SectionContent, SectionTitle } from '../../../components/WizardComponents'

export default function HasEdsWizardForm({ form, setForm }) {
	return (
		<React.Fragment>
			<SectionTitle>Información para EDS o Grandes consumidores con EDS privada:</SectionTitle>
			<SectionContent className='pt-10 pb-0'>
				<Row>
					<InputGroup as={Col}>
						<InputGroup.Text>Número de Islas:</InputGroup.Text>
						<Form.Control 
							type="number"
							value={form.branch_spec.total_islas}
							onChange={e => setForm({ 
								...form, branch_spec: { ...form.branch_spec, total_islas: e.target.value } 
							})}
							placeholder='Escriba aquí...'
						/>
					</InputGroup>
					<InputGroup as={Col}>
						<InputGroup.Text>Número de Dispensadores - Surtidores:</InputGroup.Text>
						<Form.Control 
							type="number"
							value={form.branch_spec.total_surtidores}
							onChange={e => setForm({ 
								...form, branch_spec: { ...form.branch_spec, total_surtidores: e.target.value } 
							})}
							placeholder='Escriba aquí...'
						/>
					</InputGroup>
				</Row>
				<Row>
					<InputGroup as={Col}>
						<InputGroup.Text>Número de Pistolas - Mangueras:</InputGroup.Text>
						<Form.Control 
							type="number"
							value={form.branch_spec.total_mangueras}
							onChange={e => setForm({ 
								...form, branch_spec: { ...form.branch_spec, total_mangueras: e.target.value } 
							})}
							placeholder='Escriba aquí...'
						/>
					</InputGroup>
					<InputGroup as={Col}>
						<InputGroup.Text>Tipo de llenado:</InputGroup.Text>
						<Form.Control 
							as="select"
							value={form.branch_spec.tipo_llenado}
							onChange={e => setForm({ 
								...form, branch_spec: { ...form.branch_spec, tipo_llenado: e.target.value } 
							})}
						>
							<option value="" disabled>:: Seleccione una opción ::</option>
							<option value="directo">DIRECTO</option>
							<option value="remoto">REMOTO</option>
						</Form.Control>
					</InputGroup>
				</Row>
			</SectionContent>
		</React.Fragment>
	)
}