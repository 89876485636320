import dewarApi, { getDomain } from '../../services/dewarApi'

export const getBranches = params => (
	new Promise((resolve, reject) => {
		dewarApi()
			.get('/branches', { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const getBranch = (id, params) => (
	new Promise((resolve, reject) => {
		dewarApi()
			.get(`/branches/${id}`, { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const storeBranch = data => (
	new Promise((resolve, reject) => {
		dewarApi()
			.post('/branches', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const updateBranch = (id, data) => (
	new Promise((resolve, reject) => {
		dewarApi()
			.put(`/branches/${id}`, data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const deleteBranch = id => (
	new Promise((resolve, reject) => {
		dewarApi()
			.delete(`/branches/${id}`)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const searchActiveBranches = async (name, token, scopeId) => (
	fetch( getDomain()+`/branches?filter[name]=${name}&filter[company_id]=${scopeId}&filter[active]=1`, {
		headers: {'Authorization': `Bearer ${token}`}
	})
		.then((response) => response.json())
		.then((res) =>
			res.data.map(branch => ({
				label: branch.name,
				value: branch.id,
			})),
		)
)