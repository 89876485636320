import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { message } from 'antd'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { Form, Spinner } from 'react-bootstrap'

import UserEquipmentForm from './UserEquipmentForm'

import { storeUserEquipment } from '../services'
import { handleError } from '../../../helpers'

export default function NewUserEquipmentModal({ visible, onClose, userId, reload }) {
	const { register, handleSubmit, formState: { errors } } = useForm()
	const [loading, setLoading] = useState(false)

	const onSubmit = values => {
		setLoading(true)
		storeUserEquipment({ ...values, user_id: userId })
			.then(res => {
				message.success(res.data.message)
				onClose()
				reload()
			})
			.catch(error => handleError(error, setLoading))
	}

	return (
		<React.Fragment>
			<Modal isOpen={visible}>
				<ModalHeader toggle={onClose}>Agregar Equipo de Medición</ModalHeader>
				<ModalBody>
					<Form onSubmit={handleSubmit(onSubmit)}>
						<UserEquipmentForm register={register} errors={errors} />
						<Button color='primary' type='submit' disabled={loading}>
							{ loading && <Spinner animation='border' size="sm" className='mr-5' /> }
							Agregar Equipo
						</Button>
					</Form>
				</ModalBody>
			</Modal>
		</React.Fragment>
	)
}