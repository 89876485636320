import dewarApi, { getDomain } from '../../services/dewarApi'

export const getEvidence = params => (
	new Promise((resolve, reject) => {
		dewarApi()
			.get('/evidence', { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const getSingleEvidence = (id, params) => (
	new Promise((resolve, reject) => {
		dewarApi()
			.get(`/evidence/${id}`, { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const storeEvidence = data => (
	new Promise((resolve, reject) => {
		dewarApi()
			.post('/evidence', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const updateEvidence = (id, data) => (
	new Promise((resolve, reject) => {
		dewarApi()
			.put(`/evidence/${id}`, data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const deleteEvidence = id => (
	new Promise((resolve, reject) => {
		dewarApi()
			.delete(`/evidence/${id}`)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const searchEvidence = async (name, token) => (
	fetch( getDomain()+`/evidence?filter[name]=${name}`, {
		headers: {'Authorization': `Bearer ${token}`}
	})
		.then((response) => response.json())
		.then((res) =>
			res.data.map(evidence => ({
				label: evidence.name,
				value: evidence.id,
			})),
		)
)