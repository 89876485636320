import React, { useEffect, useState } from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import { Spinner } from 'react-bootstrap'

import ServiceEvidenceTab from '../../Evidence/ServiceEvidenceTab'

import { getService } from '../services'
import { handleError } from '../../../helpers'

export default function RelatedEvidenceModal({ visible, onClose, relatedService, currentService, reloadEvidence }) {
    const [service, setService] = useState(null)

    useEffect(() => {
        !service && getService(relatedService.id, {
            include: 'initialService.verificationList,verificationList'
        })
            .then(res => setService(res.data.data))
            .catch(error => handleError(error))
    }, [ service, relatedService ])

    const handleClose = () => {
        reloadEvidence()
        onClose()
    }

    return (
        <React.Fragment>
            <Modal isOpen={visible} size="xl">
                <ModalHeader toggle={handleClose}>Evidencia del Servicio Relacionado</ModalHeader>
                <ModalBody>
                    { service ? (
                        <ServiceEvidenceTab 
                            service={service} 
                            relatedService={currentService}
                        />
                    ) : (
                        <Spinner animation='border' size="sm" />
                    )}
                </ModalBody>
            </Modal>
        </React.Fragment>
    )
}