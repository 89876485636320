import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Form } from 'react-bootstrap'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { message } from 'antd'

import BranchForm from './BranchForm'

import { updateBranch } from '../services'
import { handleError } from '../../../helpers'

export default function EditBranchModal({ visible, onClose, branch, reload }) {
	const { register, handleSubmit, formState: { errors }, reset, watch } = useForm()
	const [selectedInstalationType, setSelectedInstalationType] = useState([])

	const onSubmit = values => {
		if(selectedInstalationType.value) values.instalation_type_id = selectedInstalationType.value
		updateBranch(branch.id, values)
			.then(res => {
				message.success(res.data.message)
				reset()
				onClose()
				reload()
			})
			.catch(error => handleError(error))
	}	

	const handleClose = () => {
		reset()
		onClose()
	}

	return (
		<React.Fragment>
			<Modal isOpen={visible} toggle={handleClose}>
				<ModalHeader toggle={handleClose}>Actualizar Instalación</ModalHeader>
				<ModalBody>
					<Form onSubmit={handleSubmit(onSubmit)}>
						<BranchForm
							branch={branch} 
							register={register}
							errors={errors}
							watch={watch}
							selectedInstalationType={selectedInstalationType}
							setSelectedInstalationType={setSelectedInstalationType}
						/>
						<Button type="submit" color="primary">Actualizar</Button>
					</Form>
				</ModalBody>
			</Modal>
		</React.Fragment>
	)
}