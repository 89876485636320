import React from 'react'
import { Form } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { Modal, ModalHeader, ModalBody, Button } from 'reactstrap'
import { message } from 'antd'

import TaskTemplateForm from './TaskTemplateForm'

import { updateTaskTemplate } from '../services'
import { handleError } from '../../../helpers'

export default function EditTaskTemplateModal({ visible, onClose, taskTemplate, reload }) {
	const { register, handleSubmit, formState: { errors } } = useForm({
		defaultValues: { 
			resolved_placeholder: taskTemplate.resolved_placeholder,
			no_resolved_placeholder: taskTemplate.no_resolved_placeholder,
			no_apply_placeholder: taskTemplate.no_apply_placeholder,
			description: taskTemplate.description,
			task_type: taskTemplate.task_type,
			minminas_criteria: taskTemplate.minminas_criteria,
			general_criteria: taskTemplate.general_criteria,
			type: taskTemplate.type,
			superficial_limit: taskTemplate.superficial_limit,
		}
	})

	const onSubmit = values => {
		updateTaskTemplate(taskTemplate.id, values)
			.then(res => {
				message.success(res.data.message)
				onClose()
				reload()
			})
			.catch(error => handleError(error))
	}

	return (
		<React.Fragment>
			<Modal isOpen={visible}>
				<ModalHeader toggle={onClose}>Actualizar Plantilla de Tarea</ModalHeader>
				<ModalBody>
					<Form onSubmit={handleSubmit(onSubmit)}>
						<TaskTemplateForm register={register} errors={errors} onlyEditable />
						<Button color="primary" type="submit">Actualizar Plantilla</Button>
					</Form>
				</ModalBody>
			</Modal>
		</React.Fragment>
	)
}