import React from 'react'
import { Redirect, Route, Switch } from 'react-router'

import SettingsDashboard from './SettingsDashboard'

import UsersList from '../Users/UsersList'
import InstalationTypesList from '../InstalationTypes/InstalationTypesList'
import EvidenceTemplatesList from '../EvidenceTemplates/EvidenceTemplatesList'
import TaskTemplatesList from '../TaskTemplates/TaskTemplatesList'
import FormatVersionsList from '../FormatVersions/FormatVersionsList'

export default function Settings() {
	return (
		<React.Fragment>
			<Switch>
				<Route exact path="/settings" component={SettingsDashboard} />
				<Route exact path="/settings/users" component={UsersList} />
				<Route exact path="/settings/instalation_types" component={InstalationTypesList} />
				<Route exact path="/settings/evidence_templates" component={EvidenceTemplatesList} />
				<Route exact path="/settings/task_templates" component={TaskTemplatesList} />
				<Route exact path="/settings/format_versions" component={FormatVersionsList} />
				
				<Redirect to="/404" />
			</Switch>
		</React.Fragment>
	)
}