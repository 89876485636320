import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Empty, Table, Tooltip, Popconfirm, message, Divider } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'

import EditTankSpecModal from './EditTankSpecModal'

import { offlineUpdateReview } from '../../Services/actions'
import { queueRequest } from '../../Offline/actions'
import { deleteReviewSpec } from '../services'
import { handleError } from '../../../helpers'

export default function TankSpecsTable({ tankSpecs, reload, editable, serviceId, serviceReview }) {
	const { isConnected } = useSelector(state => state.offline)
	const [showEditModal, setShowEditModal] = useState(null)
	const dispatch = useDispatch()


	const columns = [
		{
			title: 'No Tanque',
			dataIndex: 'tank_number'
		},
		{
			title: 'Producto Almacenado',
			dataIndex: 'product_name',
			render: (t, r) => r.other_product_name ? r.other_product_name : t
		},
		{
			title: 'Capacidad (GL)',
			dataIndex: 'tank_capacity'
		},
		{
			title: 'Tipo',
			dataIndex: 'is_underground',
			render: t => t ? 'Tanque Enterrado' : 'En Superficie'
		},
		{
			title: 'Acciones',
			render: (t,r) => editable && (
				<React.Fragment>
					{ r.id ? (
						<React.Fragment>
							<Tooltip title="Editar" onClick={() => setShowEditModal(r)}>
								<FontAwesomeIcon className='text-link text-primary' icon={faEdit} />
							</Tooltip>
							<Divider type='vertical' />
							<Tooltip title="Eliminar">
								<Popconfirm
									title="Esta acción no se puede revertir"
									okText="Eliminar"
									cancelText="Cancelar"
									okButtonProps={{ danger:true }}
									onConfirm={() => isConnected ? handleDelete(r.id) : handleDeleteOffline(r.id)}
								>
									<FontAwesomeIcon className='text-link text-danger' icon={faTrash} />
								</Popconfirm>
							</Tooltip>
						</React.Fragment>
					) : <em>Una vez sincronize podra editar o eliminar</em>}
				</React.Fragment>
			)
		}
	]

	const handleDelete = id => {
		deleteReviewSpec(id)
			.then(res => {
				message.success(res.data.message)
				reload()
			})
			.catch(error => handleError(error))
	}

	const handleDeleteOffline = id => {
		dispatch(offlineUpdateReview({
			...serviceReview,
			tank_specs: serviceReview.tank_specs.filter(r => r.id !== parseInt(id))
		}, serviceId))
		dispatch(queueRequest({ method: 'DELETE', url: `/review_specs/${id}` }))
		message.info('Actualización en espera de internet')
		reload()
	}

	const expandedRowRender = record => (
		<React.Fragment>
			<p className='mb-5'><strong>Tipo (Solo Plantas): </strong>{record.tank_type}</p>
			<p className='mb-5'><strong>Diametro (Solo Plantas): </strong>{record.tank_diameter}</p>
		</React.Fragment>
	)

	return (
		<React.Fragment>
			<Table 
				dataSource={tankSpecs}
				columns={columns}
				size='small'
				loading={!tankSpecs}
				locale={{ emptyText: (
					<Empty description="No tiene islas/equipos asociados al acta" imageStyle={{ height: '70px' }} /> 
				)}}
				expandable={{ expandedRowRender }}
				pagination={false}
				rowKey={r => r.id ? r.id : Math.random()}
			/>
			{ showEditModal && (
				<EditTankSpecModal 
					visible
					onClose={() => setShowEditModal(null)}
					tankSpec={showEditModal}
					reload={reload}
					serviceId={serviceId}
					serviceReview={serviceReview}
				/>
			)}
		</React.Fragment>
	)
}