import dewarApi, { getDomain } from '../../services/dewarApi'

export const getServiceReviews = params => (
	new Promise((resolve, reject) => {
		dewarApi()
			.get('/service_reviews', { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const getServiceReview = (id, params) => (
	new Promise((resolve, reject) => {
		dewarApi()
			.get(`/service_reviews/${id}`, { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const publicGetServiceReview = (id, params) => (
	new Promise((resolve, reject) => {
		dewarApi()
			.get(`/public/service_reviews/${id}`, { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const storeServiceReview = data => (
	new Promise((resolve, reject) => {
		dewarApi()
			.post('/service_reviews', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const updateServiceReview = (id, data) => (
	new Promise((resolve, reject) => {
		dewarApi()
			.put(`/service_reviews/${id}`, data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const deleteServiceReview = id => (
	new Promise((resolve, reject) => {
		dewarApi()
			.delete(`/service_reviews/${id}`)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const searchServiceReviews = async (name, token) => (
	fetch( getDomain()+`/service_reviews?filter[name]=${name}`, {
		headers: {'Authorization': `Bearer ${token}`}
	})
		.then((response) => response.json())
		.then((res) =>
			res.data.map(service_review => ({
				label: service_review.name,
				value: service_review.id,
			})),
		)
)

export const notifyServiceReview = id => (
	new Promise((resolve, reject) => {
		dewarApi()
			.post(`/service_reviews/${id}/notify`)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)