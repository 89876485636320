import React, { useState, useEffect }  from 'react'
import { useSelector } from 'react-redux'
import { Redirect } from 'react-router'
import styled from 'styled-components'
import { Spinner } from 'react-bootstrap'
import { message } from 'antd'

import ServiceReviewDocument from '../ServiceReviews/partials/ServiceReviewDocument'

export default function ServiceReviewPreviewOffline(props) {
	const { isConnected } = useSelector(state => state.offline)
	const reviewId = props.reviewId ? props.reviewId : props.match.params.reviewId
	const serviceId = props.serviceId ? props.serviceId : props.match.params.serviceId
	const services = useSelector(state => state.services.data)
	const [serviceReview, setServiceReview] = useState(null)

	const service = services.filter(s => s.id === parseInt(serviceId))[0]

	useEffect(() => {
		if(!service) return message.error('El servicio solicitado no se encuentra disponible Offline')
		setServiceReview(service.service_inspector.service_plan.service_review)
	}, [ services, serviceId, service ])

	if(isConnected) return <Redirect to={`/service_reviews/preview/${reviewId}`} />

	if(!serviceReview) return <Spinner />

	return (
		<React.Fragment>
			<Container>
				<ServiceReviewDocument 
					data={serviceReview} 
					serviceData={service}
				/>
			</Container>
		</React.Fragment>
	)
}

const Container = styled.div`
	padding: 0px 10px;
`