import React from 'react'
import { Descriptions } from 'antd'

const { Item } = Descriptions

export default function HasEdsDetails({ branchSpec }) {
	return (
		<React.Fragment>
			<Descriptions bordered size="small" className="mt-10 mb-10" column={2}>
				<Item label="Número de islas:">
					{branchSpec && branchSpec.total_islas}
				</Item>
				<Item label="Número de dispensadores - surtidores:">
					{branchSpec && branchSpec.total_surtidores}
				</Item>
				<Item label="Número de pistolas - mangueras">
					{branchSpec && branchSpec.total_mangueras}
				</Item>
				<Item label="Tipo de llenado:">
					{branchSpec && branchSpec.tipo_llenado ? branchSpec.tipo_llenado : 'NA'}
				</Item>
			</Descriptions>
		</React.Fragment>
	)
}