import React from 'react'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { message, Upload } from "antd"
import { InboxOutlined } from '@ant-design/icons'
import AWS from "aws-sdk"

import { updateImpartialityNotice } from '../services'
import { handleError } from '../../../helpers'

const { Dragger } = Upload

export default function ImpartialityNoticeAttachmentModal({ 
	visible, onClose, impartialityNotice, reload 
}) {

	const props = {
		multiple: false,
		customRequest({
			action,
			data,
			file,
			filename,
			headers,
			onError,
			onProgress,
			onSuccess,
			withCredentials
		}) {
			AWS.config.update({
				accessKeyId: "AKIA4NB262CULJ4WQ2HK",
    			secretAccessKey: "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"
			});
		
			const S3 = new AWS.S3({
				params: { Bucket: 'dewar' },
				region: 'us-east-1',
		  });

		  let fileExt = file.name.split('.').pop();
		
			const objParams = {
				Bucket: "dewar",
				Key: "app/impartiality_notices/" + impartialityNotice.id + '.' + fileExt,
				Body: file,
				ContentType: file.type
			};
		
			S3.putObject(objParams)
				.on("httpUploadProgress", function({ loaded, total }) {
					onProgress(
						{ percent: Math.round((loaded / total) * 100) },
						file
					);
				})
				.send(function(err, data) {
					if (err) {
						onError();
						console.error('Error al subir imagen: ', err.message)
					} else {
						onSuccess(data.response, file);						
						updateImpartialityNotice(impartialityNotice.id, {
							attachment_uri: `https://dewar.s3.us-east-1.amazonaws.com/app/impartiality_notices/${impartialityNotice.id}.${fileExt}`
						})
							.then(res => {
								message.success(res.data.message)
								reload()
								onClose()
							})
							.catch(error => handleError(error))
					}
				});
			}
		};

	return (
		<React.Fragment>
			<Modal isOpen={visible}>
				<ModalHeader toggle={onClose}>Adjunto Ratificación de Imparcialidad</ModalHeader>
				<ModalBody>
					{ impartialityNotice.attachment_uri && (
						<a href={impartialityNotice.attachment_uri} target="_blank" rel="noreferrer">
							<Button color='primary' className='mb-5'>Ver documento adjunto</Button>
						</a>
					)}
					<Dragger {...props}>
						<p className="ant-upload-drag-icon">
							<InboxOutlined />
						</p>
						<p className="ant-upload-text">Haga clic o arraste el adjunto</p>
						<p className="ant-upload-hint">
							Solo se permite adjuntar un archivo menor a 5MB
						</p>
					</Dragger>
				</ModalBody>
			</Modal>
		</React.Fragment>
	)
}