import React from 'react'
import { Col, Row } from 'reactstrap'
import styled from 'styled-components'
import { Descriptions, Table } from 'antd'
import moment from 'moment'

import DocumentHeader from '../../../../components/DocumentHeader'
import logo from '../../../../assets/images/logo_dewar_onac.png'

const { Item } = Descriptions

export default function SERVICE_REPORT_TEMPLATE_INS01({ serviceReport }) {

	const serviceReview = serviceReport.service_review
	const service = serviceReview.service_plan.service_inspector.service

	return (
		<Document>
			<DocumentHeader 
				title="INFORME DE INSPECCIÓN"
				version={serviceReport.format_version.sku}
				expireAt={serviceReport.format_version.expire_at}
				proposalSku={service.proposal.sku}
				logoSrc={logo}
			/>
			<p className='section_title'>1. INFORMACIÓN GENERAL</p>
			<Descriptions column={2} bordered size='small'>
				<Item label="Fecha de inspección:">
					{ moment(serviceReview.service_plan.inspected_at).format('DD-MMM-YYYY').toUpperCase() }
				</Item>
				<Item label="N° Asignación inspección">
					{serviceReview.service_plan.service_inspector.sku}
				</Item>
				<Item label="Tipo de inspección" span={2}>
					{service.type}
				</Item>
			</Descriptions>
			<p className='section_title'>2. INFORMACIÓN INSTALACIÓN</p>
			<Descriptions column={2} bordered size='small'>
				<Item label="Razón Social:">
					{service.proposal.inquiry.branch_spec.branch.company.name}
				</Item>
				<Item label="NIT">
					{service.proposal.inquiry.branch_spec.branch.company.complete_document}
				</Item>
				<Item label="Representante Legal:" span={2}>
					{service.proposal.inquiry.branch_spec.branch.company.legal_owner_name}
				</Item>
				<Item label="Nombre de Instalación:">
					{service.proposal.inquiry.branch_spec.branch.name}
				</Item>
				<Item label="Tipo de Instalación:">
					{service.proposal.inquiry.branch_spec.branch.instalation_type_name}
				</Item>
				<Item label="Correo electrónico">
					{service.proposal.inquiry.branch_spec.branch.email}
				</Item>
				<Item label="Tipo de Servicio (Público o Privado):"></Item>
				<Item label="Dirección:">
					{service.proposal.inquiry.branch_spec.branch.address_line}
				</Item>
				<Item label="Ciudad:">
					{service.proposal.inquiry.branch_spec.branch.address_state},{" "}
					{service.proposal.inquiry.branch_spec.branch.address_city}
				</Item>
				<Item label="Teléfono Fijo:">
					{service.proposal.inquiry.branch_spec.branch.phone}
				</Item>
				<Item label="Celular:">
					{service.proposal.inquiry.branch_spec.branch.mobile}
				</Item>
			</Descriptions>
			<p className='section_title'>3. CARACTERÍSTICAS TÉCNICAS DE LA INSTALACIÓN</p>
			<Descriptions column={3} bordered size='small'>
				<Item label="Tanques Enterrados">
					{ serviceReview.total_tanks_underground } tanques
				</Item>
				<Item label="Tanques En Superficie">
					{ serviceReview.total_tanks_ground } tanques
				</Item>
				<Item label="Capacidad Total (GL)">
					{ serviceReview.total_tanks_capacity } GL
				</Item>
			</Descriptions>
			<Table
				dataSource={serviceReview.tank_specs}
				columns={[
					{
						title: 'No Tanque',
						dataIndex: 'tank_number'
					},
					{
						title: 'Producto Almacenado',
						dataIndex: 'product_name',
						render: (t, r) => `${t}${r.other_product_name ? `- ${r.other_product_name}` : ''}`
					},
					{
						title: 'Capacidad (GL)',
						dataIndex: 'tank_capacity'
					},
					{
						title: 'Tipo',
						dataIndex: 'is_underground',
						render: t => t ? 'Tanque Enterrado' : 'En Superficie'
					},
				]}
				size="small"
				pagination={false}
				rowKey={r => r.id}
				className='mt-15'
			/>
			{ serviceReview.island_specs.length > 0 && (
				<Table 
					dataSource={serviceReview.island_specs}
					columns={[
						{
							title: 'No Isla',
							dataIndex: 'island_number'
						},
						{
							title: 'No. Equipos de suministro',
							dataIndex: 'equipos_suministro_total',
						},
						{
							title: 'No. Mangueras',
							dataIndex: 'mangueras_total'
						}
					]}
					size="small"
					pagination={false}
					rowKey={r => r.id}
					className='mt-15'
				/>
			)}
			<p className='section_title'>4. PROCESO DE INSPECCIÓN</p>
			<Descriptions bordered size='small' column={2}>
				<Item label="Inspección Inicial">
					Fecha: {moment(serviceReport.initial_inspection_at).format('DD-MMM-YYYY').toUpperCase()}
				</Item>
				<Item label="Inspección Cierre (si aplica)">
					Fecha: {serviceReport.retry_inspection_at ? moment(serviceReport.retry_inspection_at).format('DD-MMM-YYYY').toUpperCase() : 'NA'}
				</Item>
			</Descriptions>
			<p className='section_title'>5. EQUIPOS DE MEDICIÓN EMPLEADOS EN LA INSPECCIÓN</p>
			<Table 
				dataSource={serviceReview.review_equipments}
				columns={[
					{
						title: 'Item',
						dataIndex: 'item'
					},
					{
						title: 'Equipo',
						dataIndex: 'name'
					},
					{
						title: 'Marca',
						dataIndex: 'brand'
					},
					{
						title: 'Serial',
						dataIndex: 'serial'
					},
					{
						title: 'Fecha de calibración / verificación',
						dataIndex: 'verified_at',
						render: t => moment(t).format('DD-MMM-YYYY').toUpperCase()
					},
				]}
				size="small"
				pagination={false}
				rowKey={r => r.id}
				className='mt-15'
			/>
			<p className='section_title'>6. RESULTADOS DEL PROCESO</p>
			<Descriptions bordered size='small' column={1}>
				<Item label="N° de No conformidades halladas">
					{ parseInt(serviceReport.total_nc_tasks) }
				</Item>
				<Item label="N° de No conformidades resueltas">
					{serviceReport.total_resolved_tasks}
				</Item>
				<Item label="N° No conformidades sin resolver">
					{parseInt(serviceReport.total_nc_tasks) - parseInt(serviceReport.total_resolved_tasks) }
				</Item>
			</Descriptions>
			{/* <p className='section_title'>6.1. NO CONFORMIDADES SIN RESOLVER (SI APLICA)</p>
			<Descriptions bordered size='small' column={1}>
				<Item label="Ver Reporte de No Conformidades FRN33">Nº {serviceReview.non_compliant_report.id}</Item>
				<Item label="Observaciones:">{serviceReview.observations}</Item>
			</Descriptions> */}
			<p className='section_title'>7. EXPEDIENTE RELACIONADO</p>
			<div className='detail_container'>
				{`Expediente ${service.proposal.sku} Soportes evidencias documentales y fotográficas`}
			</div>
			<p className='section_title'>8. REGISTRO FOTOGRÁFICO</p>
				<Row className='p-10'>
					{ serviceReport.report_evidence.map(evidence => (
						<Col key={evidence.id} sm="4" style={{ height: '200px' }} className='bg-light text-center'>
							<img src={evidence.attachment_uri} width="100%" style={{ maxHeight: '200px', objectFit: 'contain' }} alt="Foto" />
						</Col>
					)) }
				</Row>
			<p className='section_title'>9. RESULTADO FINAL DE LA INSPECCIÓN</p>
			<Descriptions bordered size="small" column={1}>
				<Item label="La instalación, respecto a los criterios de inspección se encuentra:">
					{ serviceReview.is_compliant === 1 ? 'Conforme' : serviceReview.is_compliant === 0 ? 'No Conforme' : <em>Sin registro</em> }
				</Item>
				<Item label="Observaciones adicionales:">
					{ serviceReview.observations ? serviceReview.observations : <em>Sin registro</em> }
				</Item>
				<Item label="Inspector:">
					{ serviceReview.service_plan.service_inspector.user.name }
				</Item>
				<Item label="Fecha de Informe:">
					{ moment(serviceReport.reported_at).format('DD-MMM-YYYY').toUpperCase() }
				</Item>
			</Descriptions>
			<p className='section_title'>DECISIÓN COMITÉ TÉCNICO</p>
			<Descriptions bordered size="small" column={2} className='mb-5'>
				<Item label="Consecutivo Comité Técnico:">
					{ serviceReport.committee_sku_formated ? serviceReport.committee_sku_formated : <em>Sin consecutivo asignado</em> }
				</Item>
				<Item label="Fecha de Comité:">
					{ serviceReport.committee_at ? moment(serviceReport.committee_at).format('DD-MMM-YYYY').toUpperCase() : <em>Sin registro</em> }
				</Item>
			</Descriptions>
			<Descriptions bordered size="small" column={1}>
				<Item label="El Comité Técnico decide que la Instalación objeto del proceso de inspección es:">
					{serviceReport.is_committee_compliant === 1 ? 'Conforme' : serviceReport.is_committee_compliant === 0 ? 'No Conforme' : <em>Sin registro</em>}
				</Item>
				<Item label="Observaciones adicionales:">
					{ serviceReport.committee_observations ? serviceReport.committee_observations : <em>Sin registro</em> }
				</Item>
			</Descriptions>
		</Document>
	)
}

const Document = styled.div`
	background-color: #fff;
	padding: 15px;
	margin: auto;
	p {
		font-size: 15px;
	}
	span.variable {
		color: #e78035;
		font-weight: 600;
	}
	h5 {
		font-size: 21px;
	}
	h6 {
		margin-top: 10px;
		font-size: 17px;
	}
	.details_container {
		padding: 15px;
		border: 2px dashed #888;
		margin-bottom: 20px;
	}
	.section_title {
		font-weight: bold;
		margin-top: 20px;
		margin-bottom: 10px;
	}
`