import dewarApi, { getDomain } from '../../services/dewarApi'

export const getRetryNotices = params => (
	new Promise((resolve, reject) => {
		dewarApi()
			.get('/retry_notices', { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const getRetryNotice = (id, params) => (
	new Promise((resolve, reject) => {
		dewarApi()
			.get(`/retry_notices/${id}`, { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const publicGetRetryNotice = (id, params) => (
	new Promise((resolve, reject) => {
		dewarApi()
			.get(`/public/retry_notices/${id}`, { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const storeRetryNotice = data => (
	new Promise((resolve, reject) => {
		dewarApi()
			.post('/retry_notices', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const updateRetryNotice = (id, data) => (
	new Promise((resolve, reject) => {
		dewarApi()
			.put(`/retry_notices/${id}`, data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const deleteRetryNotice = id => (
	new Promise((resolve, reject) => {
		dewarApi()
			.delete(`/retry_notices/${id}`)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const searchRetryNotices = async (name, token) => (
	fetch( getDomain()+`/retry_notices?filter[name]=${name}`, {
		headers: {'Authorization': `Bearer ${token}`}
	})
		.then((response) => response.json())
		.then((res) =>
			res.data.map(retry_notice => ({
				label: retry_notice.name,
				value: retry_notice.id,
			})),
		)
)

export const notifyRetryNotice = id => (
	new Promise((resolve, reject) => {
		dewarApi()
			.post(`/retry_notices/${id}/notify`)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)