import dewarApi, { getDomain } from '../../services/dewarApi'

export const getServicePlans = params => (
	new Promise((resolve, reject) => {
		dewarApi()
			.get('/service_plans', { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const getServicePlan = (id, params) => (
	new Promise((resolve, reject) => {
		dewarApi()
			.get(`/service_plans/${id}`, { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const storeServicePlan = data => (
	new Promise((resolve, reject) => {
		dewarApi()
			.post('/service_plans', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const updateServicePlan = (id, data) => (
	new Promise((resolve, reject) => {
		dewarApi()
			.put(`/service_plans/${id}`, data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const deleteServicePlan = id => (
	new Promise((resolve, reject) => {
		dewarApi()
			.delete(`/service_plans/${id}`)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const publicGetServicePlan = (id, params) => (
	new Promise((resolve, reject) => {
		dewarApi()
			.get(`/public/service_plans/${id}`, { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const searchServicePlans = async (name, token) => (
	fetch( getDomain()+`/service_plans?filter[name]=${name}`, {
		headers: {'Authorization': `Bearer ${token}`}
	})
		.then((response) => response.json())
		.then((res) =>
			res.data.map(service_plan => ({
				label: service_plan.name,
				value: service_plan.id,
			})),
		)
)

export const notifyServicePlan = id => (
	new Promise((resolve, reject) => {
		dewarApi()
			.post(`/service_plans/${id}/notify`)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)