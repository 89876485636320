import React from 'react'
import styled from 'styled-components'

import Logo from '../assets/images/logo.png'

export default function AuthLayout({ children }) {
	return (
		<Container>
			<img src={Logo} alt="Logo Dewar" width="210px" />
			<FormContainer>
				{children}
			</FormContainer>
			<p>Desarrollado por{" "}
				<a href="//smart4.com.co" target="_blank" rel="noreferrer">
					Smart4 Solutions
				</a>
			</p>
		</Container>
	)
}

const Container = styled.div`
	width: 100%;
	min-height: 100vh;
	background-color: #f7f8f9;	
	display: flex;
  	justify-content: center;
	align-items: center;
	flex-direction: column;
`

const FormContainer = styled.div`
	background-color: #fff;
	padding: 35px;	
	max-width: 450px;
	width: 100%;
	text-align: center;
	margin: 20px auto;
`