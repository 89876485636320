import React from 'react'
import { Descriptions } from 'antd'

const { Item } = Descriptions

export default function HasHighVolumeDetails({ branchSpec }) {
	return (
		<React.Fragment>
			<Descriptions bordered size="small" className="mt-10 mb-10" column={2}>
				<Item label="Número de brazos de llenado:">
					{branchSpec && branchSpec.total_brazos}
				</Item>
				<Item label="Número de líneas de recepción:">
					{branchSpec && branchSpec.total_lineas_recepcion}
				</Item>
				<Item label="Número de islas de recepción:">
					{branchSpec && branchSpec.total_islas_recepcion}
				</Item>
				<Item label="Número de recintos para tanques aéreos:">
					{branchSpec && branchSpec.total_recintos}
				</Item>
			</Descriptions>
		</React.Fragment>
	)
}