import React from 'react'
import { Descriptions } from 'antd'

const { Item } = Descriptions

export default function PastCertificationDetails({ inquiry }) {
	return (
		<React.Fragment>
			<Descriptions bordered size="small" className="mt-10 mb-10" column={2}>
				<Item label="Tiene certificación anterior:">
					{inquiry && inquiry.has_previous_certification ? 'SI' : 'NO'}
				</Item>
				{ inquiry && inquiry.has_previous_certification === 1 && (
					<React.Fragment>
						<Item label="Nombre Organismo Evaluador de la Conformidad">
							{inquiry && inquiry.previous_competitor}
						</Item>
						<Item label="Fecha emisión">
							{inquiry && inquiry.previous_start_at}
						</Item>
					</React.Fragment>
				)}
			</Descriptions>
		</React.Fragment>
	)
}