import dewarApi, { getDomain } from '../../services/dewarApi'

export const getVerificationTasks = params => (
	new Promise((resolve, reject) => {
		dewarApi()
			.get('/verification_tasks', { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const publicGetVerificationTasks = params => (
	new Promise((resolve, reject) => {
		dewarApi()
			.get('/public/verification_tasks', { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const getVerificationTask = (id, params) => (
	new Promise((resolve, reject) => {
		dewarApi()
			.get(`/verification_tasks/${id}`, { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const storeVerificationTask = data => (
	new Promise((resolve, reject) => {
		dewarApi()
			.post('/verification_tasks', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const updateVerificationTask = (id, data) => (
	new Promise((resolve, reject) => {
		dewarApi()
			.put(`/verification_tasks/${id}`, data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const deleteVerificationTask = id => (
	new Promise((resolve, reject) => {
		dewarApi()
			.delete(`/verification_tasks/${id}`)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const searchVerificationTasks = async (name, token) => (
	fetch( getDomain()+`/verification_tasks?filter[name]=${name}`, {
		headers: {'Authorization': `Bearer ${token}`}
	})
		.then((response) => response.json())
		.then((res) =>
			res.data.map(verification_task => ({
				label: verification_task.name,
				value: verification_task.id,
			})),
		)
)

export const importVerificationTasks = data => (
	new Promise((resolve, reject) => {
		dewarApi()
			.post('/verification_tasks/import', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)