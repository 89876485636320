import React, { useState } from 'react'
import { Modal, ModalBody, ModalHeader, Spinner, Button } from 'reactstrap'
import { Divider, Empty, message, Popconfirm } from 'antd'

import { handleError } from '../../../helpers'
import { deleteEvidence } from '../services'
import CloneFileModal from './CloneFileModal'

export default function EvidenceAttachmentsModal({ 
	visible, onClose, reloadEvidence, evidenceTemplate, evidence, evidenceTemplates, relatedService
}) {
	const [showCloneModal, setShowCloneModal] = useState(null)
	
	const currentEvidence = evidence.filter(e => e.evidence_template_id === evidenceTemplate.id)

	const handleDelete = id => {
		deleteEvidence(id)
			.then(res => {
				message.success(res.data.message)
				reloadEvidence()
			})
			.catch(error => handleError(error))
	}

	return (
		<React.Fragment>
			<Modal isOpen={visible} toggle={onClose}>
				<ModalHeader toggle={onClose}>Adjuntos No Evidencia #{evidenceTemplate.item}</ModalHeader>
				<ModalBody>
					{ currentEvidence ? (
						<React.Fragment>
							{ currentEvidence.length > 0 ? (
								<React.Fragment>
									{ currentEvidence.map( (singleEvidence, i) => (
										<div key={i} className="mb-10 bg-light p-10">
											<a target="_blank" href={singleEvidence.attachment_uri} rel="noreferrer">
												<Button color='primary' outline size="sm">
													Ver Evidencia #{i + 1}
												</Button>
											</a>
											<Divider type='vertical' />
											<Button 
												size="sm" 
												color="primary" 
												onClick={() => setShowCloneModal(singleEvidence)}
												outline 
											>
												Clonar
											</Button>
											<Divider type='vertical' />
											<Popconfirm
												title="Esta acción no se puede revertir"
												okText="Eliminar"
												cancelText="Cancelar"
												okButtonProps={{ danger: true }}
												onConfirm={() => handleDelete(singleEvidence.id)}
											>
												<Button color="danger" outline size="sm">Eliminar</Button>
											</Popconfirm>
										</div>
									)) }
								</React.Fragment>
							) : (
								<Empty description="Aún no se tiene adjuntos para este item" />
							)}
						</React.Fragment>
					) : (
						<Spinner size="sm" />
					)}
				</ModalBody>
			</Modal>
			{ showCloneModal && (
				<CloneFileModal 
					visible
					onClose={() => setShowCloneModal(false)}
					evidence={showCloneModal}
					evidenceTemplate={evidenceTemplate}
					evidenceTemplates={evidenceTemplates}
					relatedService={relatedService}
					reload={() => {
						setShowCloneModal(false)
						reloadEvidence()
						onClose()
					}}
				/>
			)}
		</React.Fragment>
	)
}