import React, { useState, useEffect } from 'react'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'

import EmployeesTable from './partials/EmployeesTable'
import NewEmployeeModal from './partials/NewEmployeeModal'

import { getEmployees } from './services'
import { handleError } from '../../helpers'

export default function CompanyEmployeesModal({ visible, onClose, company }) {
	const [employees, setEmployees] = useState(null)
	const [showNewModal, setShowNewModal] = useState(false)

	useEffect(() => {
		!employees && getEmployees({ 'filter[company_id]': company.id })
			.then(res => setEmployees(res.data.data))
			.catch(error => handleError(error))
	}, [employees, company])

	return (
		<React.Fragment>
			<Modal isOpen={visible} size="lg">
				<ModalHeader toggle={onClose}>Personal vinculado a cliente</ModalHeader>
				<ModalBody>
					<EmployeesTable
						employees={employees}
						reload={() => setEmployees(null)}
					/>
					<Button color="primary" onClick={() => setShowNewModal(true)} className='mt-10'>Agregar Nuevo</Button>
				</ModalBody>
			</Modal>
			{ showNewModal && (
				<NewEmployeeModal 
					visible
					onClose={() => setShowNewModal(false)}
					reload={() => setEmployees(null)} 
					companyId={company.id}
				/>
			)}
		</React.Fragment>
	)
}