import dewarApi from '../../services/dewarApi'

export const getPaymentReceipts = params => (
	new Promise((resolve, reject) => {
		dewarApi()
			.get('/payment_receipts', { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const storePaymentReceipt = data => (
	new Promise((resolve, reject) => {
		dewarApi()
			.post('/payment_receipts', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const updatePaymentReceipt = (id, data) => (
	new Promise((resolve, reject) => {
		dewarApi()
			.put(`/payment_receipts/${id}`, data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const deletePaymentReceipt = id => (
	new Promise((resolve, reject) => {
		dewarApi()
			.delete(`/payment_receipts/${id}`)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)