import React from 'react'
import { message, Upload } from "antd"
import { InboxOutlined } from '@ant-design/icons'
import AWS from "aws-sdk"

import { storeEvidence } from '../services'
import { handleError } from '../../../helpers'

const { Dragger } = Upload

export default function NewEvidenceDragAndDrop({ evidenceTemplate,  verificationListId, reloadEvidence }) {
	const props = {
		multiple: true,
		customRequest({
			action,
			data,
			file,
			filename,
			headers,
			onError,
			onProgress,
			onSuccess,
			withCredentials
		}) {

			AWS.config.update({
				accessKeyId: "AKIA4NB262CULJ4WQ2HK",
    			secretAccessKey: "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"
			});
		
			const S3 = new AWS.S3({
				params: { Bucket: 'dewar' },
				region: 'us-east-1',
		  });

		  let fileExt = file.name.split('.').pop();
		  let uri = `app/evidence/${verificationListId}/${Date.now()}.${fileExt}`
		
			const objParams = {
				Bucket: "dewar",
				Key: uri,
				Body: file,
				ContentType: file.type
			};
		
			S3.putObject(objParams)
				.on("httpUploadProgress", function({ loaded, total }) {
					onProgress(
						{ percent: Math.round((loaded / total) * 100) },
						file
					);
				})
				.send(function(err, data) {
					if (err) {
						onError();
						console.error('Error al subir imagen: ', err.message)
					} else {
						onSuccess(data.response, file);						
						storeEvidence({
							verification_list_id: verificationListId,
							evidence_template_id: evidenceTemplate.id,
							attachment_uri: `https://dewar.s3.us-east-1.amazonaws.com/${uri}`
						})
							.then(res => {
								message.success(res.data.message)
								reloadEvidence()
							})
							.catch(error => handleError(error))
					}
				});
			}
		};

	return (
		<div style={{ maxWidth: '200px' }}>
			<Dragger {...props} className="px-10">
				<p className="ant-upload-drag-icon mb-5">
					<InboxOutlined style={{ color: '#e78035', fontSize: '41px' }} />
				</p>
				<p className="bold">Haga clic o arraste</p>
				<p className="small">
					Solo se permite adjuntar un archivo menor a 5MB
				</p>
			</Dragger>
		</div>
	)
}