import dewarApi, { getDomain } from '../../services/dewarApi'

export const getNonCompliantReports = params => (
	new Promise((resolve, reject) => {
		dewarApi()
			.get('/non_compliant_reports', { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const getNonCompliantReport = (id, params) => (
	new Promise((resolve, reject) => {
		dewarApi()
			.get(`/non_compliant_reports/${id}`, { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const storeNonCompliantReport = data => (
	new Promise((resolve, reject) => {
		dewarApi()
			.post('/non_compliant_reports', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const updateNonCompliantReport = (id, data) => (
	new Promise((resolve, reject) => {
		dewarApi()
			.put(`/non_compliant_reports/${id}`, data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const deleteNonCompliantReport = id => (
	new Promise((resolve, reject) => {
		dewarApi()
			.delete(`/non_compliant_reports/${id}`)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const searchNonCompliantReports = async (name, token) => (
	fetch( getDomain()+`/non_compliant_reports?filter[name]=${name}`, {
		headers: {'Authorization': `Bearer ${token}`}
	})
		.then((response) => response.json())
		.then((res) =>
			res.data.map(non_compliant_report => ({
				label: non_compliant_report.name,
				value: non_compliant_report.id,
			})),
		)
)

export const notifyNonCompliantReport = id => (
	new Promise((resolve, reject) => {
		dewarApi()
			.post(`/non_compliant_reports/${id}/notify`)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)