import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Card, CardBody } from 'reactstrap'

import SyncLogsTable from './partials/SyncLogsTable'
import InternetRequired from '../Offline/partials/InternetRequired'
import ListTopBar from '../../components/ListTopBar'

import { getSyncLogs } from './services'
import { handleError, parsePagination } from '../../helpers'

export default function SyncLogsList() {
	const { isConnected } = useSelector(state => state.offline)
	const [syncLogs, setSyncLogs] = useState(null)
	const [pagination, setPagination] = useState({page: 1, per_page: 15, total: 0})

	useEffect(() => {
		!syncLogs && isConnected && getSyncLogs({
			...pagination
		})
			.then(res => {
				setSyncLogs(res.data.data)
				setPagination(parsePagination(res))
			})
			.catch(error => handleError(error))

	}, [ syncLogs, pagination, isConnected ])

	if(!isConnected) return <InternetRequired />
	
	return (
		<React.Fragment>
			<ListTopBar
				title="Sincronizaciones pendientes"
				subtitle="Listado de sincronizaciones no finalizadas"
			/>
			<Card>
				<CardBody>
					<SyncLogsTable
						syncLogs={syncLogs} 
						reload={()=>setSyncLogs(null)}
						pagination={pagination}
						setPagination={setPagination}
					/>
				</CardBody>
			</Card>
		</React.Fragment>
	)
}