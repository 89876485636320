import React, { useState } from 'react'
import { Alert, Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { message } from 'antd'

import dewarApi from '../../../services/dewarApi'
import { deleteSyncLog } from '../services'
import { handleError } from '../../../helpers'

export default function RetrySyncLogModal({ visible, onClose, syncLog, reload }) {
	const [loading, setLoading] = useState(false)
	const [messageForm, setMessageForm] = useState('Al enviar este formulario esta intentando sincronizar de nuevo el registro seleccionado')

	const onSubmit = () => {
		setLoading(true)
		let process = syncLog.payload
		let request = null
		if(process.method === 'PUT') {
			request = dewarApi().put(process.url, process.data)
		} else if(process.method === 'POST') {
			request = dewarApi().post(process.url, process.data)
		} else if(process.method === 'DELETE') {
			request = dewarApi().delete(process.url, process.data)
		}
		request.then(res => handleSuccess(res))
			.then(() => setMessageForm('Registro sincronizado exitosamente'))
			.catch(error => handleError(error))
	}

	const handleSuccess = res => {
		message.success(res.data.message)
		setMessageForm('Registro sincronizado exitosamente')
		deleteSyncLog(syncLog.id)
			.then(() => {
				reload()
				onClose()
			})
			.catch(error => handleError(error))
	}

	return (
		<React.Fragment>
			<Modal isOpen={visible} toggle={onClose}>
				<ModalHeader toggle={onClose}>Reintentar Sincronización</ModalHeader>	
				<ModalBody>
					<Alert color="warning">{messageForm}</Alert>
					<Button color="primary" onClick={onSubmit} disabled={loading}>
						Reintentar el proceso
					</Button>
				</ModalBody>
			</Modal>
		</React.Fragment>
	)
}	