import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Button } from 'reactstrap'
import { Descriptions, Divider, Empty, Switch } from 'antd'
import moment from 'moment'

import NewVerificationListModal from './partials/NewVerificationListModal'
import VerificationListTasksTable from './partials/VerificationListTasksTable'
import PendingTasksListModal from './partials/PendingTasksListModal'
import TasksImportModal from './partials/TasksImportModal'

import { getVerificationTasks } from '../VerificationTasks/services'
import { handleError, parsePagination } from '../../helpers'

const { Item } = Descriptions

export default function VerificationListTab({ service, reload, filters, setFilters }) {
	const [showNewModal, setShowNewModal] = useState(false)
	const [showTasksModal, setShowTasksModal] = useState(false)
	const [showImportModal, setShowImportModal] = useState(false)
	const [verificationTasks, setVerificationTasks] = useState(null)
	const [pagination, setPagination] = useState({page: 1, per_page: 15, total: 0})

	const verificationList =  service.verification_list

	useEffect(() => {
		!verificationTasks && verificationList && getVerificationTasks({
			...pagination,
			'filter[verification_list_id]': verificationList.id,
			'filter[only_unresolved]': filters['onlyUncompliant'] ? true : null,
			include: 'taskTemplate',
			sort: 'sku',
		})
			.then(res => {
				setVerificationTasks(res.data.data)
				setPagination(parsePagination(res))
			})
			.catch(error => handleError(error))
	}, [ verificationTasks, verificationList, filters, pagination ])

	const updateTasks = () => {
		getVerificationTasks({
			page: pagination.current_page,
			per_page: pagination.per_page,
			'filter[verification_list_id]': verificationList.id,
			'filter[only_unresolved]': filters['onlyUncompliant'] ? true : null,
			include: 'taskTemplate',
			sort: 'sku',
		})
			.then(res => setVerificationTasks(res.data.data))
			.catch(error => handleError(error))
	}

	return (
		<React.Fragment>
			<h6 className='mt-10 mb-0 text-center'>Lista de Verificación</h6>
			{ verificationList ? (
				<React.Fragment>
					<Descriptions bordered size="small" className='mt-15' column={2}>
						<Item label="Versión">{verificationList.format_version.sku}</Item>
						<Item label="Fecha Vigencia">{moment(verificationList.format_version.expire_at).format('DD-MMM-YYYY').toUpperCase()}</Item>
					</Descriptions>
					<p className='bold mt-15'>TAREAS REGISTRADAS EN LISTA</p>
					<div className='mb-10 text-right'>
						<Switch 
							checked={filters['onlyUncompliant']} 
							onChange={() => {
								setFilters({ ...filters, onlyUncompliant: !filters['onlyUncompliant'] })
								setVerificationTasks(null)
							}} 
						/>
						{" "}Ver solo no conformidades
					</div>
					<VerificationListTasksTable
						editable
						verificationList={verificationList}
						verificationTasks={verificationTasks}
						reloadTasks={() => setVerificationTasks(null)}
						updateTasks={updateTasks}
						isRetry={service.initial_service_id ? true : false}
						pagination={pagination}
						setPagination={setPagination}
						serviceId={service.id}
					/>
					{ !service.initial_service && (
						<div className='text-right mt-15 mb-10'>
							<p className='text-link inline' onClick={() => setShowTasksModal(true)}>
								Ver Tareas Pendientes
							</p>
						</div>
					)}
					<Link to={`/verification_lists/preview/${verificationList.id}`}>
						<Button color="primary" outline className='mt-10'>Formato de Lista de Verificación</Button>
					</Link>
					<Divider type="vertical" />
					<a href="https://links.smart4.com.co/dewar/tablas_anexas.pdf" target="_blank" rel="noreferrer">
						<Button color="primary" outline className='mt-10'>Tablas Anexas</Button>
					</a>
					<div className='mt-10'>
						<Button color="primary" outline className='mt-10' onClick={() => setShowImportModal(true)}>
							Importar Masivamente
						</Button>
					</div>
				</React.Fragment>
			) : (
				<Empty description="No se tiene una lista de verificación aún" className='pt-50'>
					<Button color="primary" onClick={() => setShowNewModal(true)}>
						Iniciar Lista de Verficación
					</Button>
				</Empty>
			)}
			{ showNewModal && (
				<NewVerificationListModal 
					visible
					onClose={() => setShowNewModal(false)}
					serviceId={service.id}
					reload={reload}
				/>
			)}
			{ showTasksModal && (
				<PendingTasksListModal
					visible
					onClose={() => setShowTasksModal(false)}
					verificationList={verificationList}
					instalationTypeId={service.proposal.inquiry.branch_spec.branch.instalation_type_id}
					serviceReviewId={service.service_inspector.service_plan.service_review.id}
					reload={reload}
					editable={service.is_editable}
					filters={filters['pendingTasks']}
					setFilters={values => setFilters({ ...filters, pendingTasks: values })}
				/>
			)}
			{ showImportModal && (
				<TasksImportModal 
					visible
					onClose={() => {
						setShowImportModal(false)
						reload()
					}}
					verificationListId={verificationList.id}
					serviceReviewId={service.service_inspector.service_plan.service_review.id}
				/>
			)}
		</React.Fragment>
	)
}