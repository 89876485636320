import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useForm } from 'react-hook-form'
import { Form } from 'react-bootstrap'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { message } from 'antd'

import TankSpecForm from './TankSpecForm'

import { offlineUpdateReview } from '../../Services/actions'
import { queueRequest } from '../../Offline/actions'
import { updateReviewSpec } from '../services'
import { handleError } from '../../../helpers'

export default function EditTankSpecModal({ visible, onClose, tankSpec, reload, serviceId, serviceReview }) {
	const { isConnected } = useSelector(state => state.offline)
	const { register, handleSubmit, formState: { errors }, watch } = useForm({
		defaultValues: {
			tank_number: tankSpec.tank_number,
			product_name: tankSpec.product_name,
			tank_capacity: tankSpec.tank_capacity,
			is_underground: tankSpec.is_underground,
			tank_type: tankSpec.tank_type,
			tank_diameter: tankSpec.tank_diameter,
			other_product_name: tankSpec.other_product_name ? tankSpec.other_product_name : '',
		}
	})
	const dispatch = useDispatch()

	const onSubmit = values => {
		if(isConnected){
			updateReviewSpec(tankSpec.id, values)
				.then(res => {
					message.success(res.data.message)
					reload()
					onClose()
				})
				.catch(error => handleError(error))
		} else {
			dispatch(offlineUpdateReview({
				...serviceReview,
				tank_specs: [
					...serviceReview.tank_specs.filter(r => r.id !== tankSpec.id),
					{ ...tankSpec, ...values }
				]
			}, serviceId))
			dispatch(queueRequest({ url: `/review_specs/${tankSpec.id}`, data: values }))
			message.info('Actualización en espera de internet')
			reload()
			onClose()
		}
	}

	return (
		<React.Fragment>
			<Modal isOpen={visible}>
				<ModalHeader toggle={onClose}>Actualizar características de tanque</ModalHeader>
				<ModalBody>
					<Form onSubmit={handleSubmit(onSubmit)}>
						<TankSpecForm register={register} errors={errors} watch={watch} tankSpec={tankSpec} />
						<Button color='primary' type="submit">Actualizar Tanque</Button>
					</Form>
				</ModalBody>
			</Modal>
		</React.Fragment>
	)
}