import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Modal, ModalBody, ModalHeader, Button } from 'reactstrap'
import { Form, Spinner, Row, Col } from 'react-bootstrap'
import { message } from 'antd'
import moment from 'moment'

import { updateProposal } from '../services'
import { handleError, currency, round } from '../../../helpers'

export default function EditProposalModal({ visible, onClose, proposal, reload }) {
    const [loading, setLoading] = useState(false)
    const { register, handleSubmit, formState: { errors }, watch } = useForm({
        defaultValues: {
            elaborated_at: moment(proposal.elaborated_at).format('YYYY-MM-DD'),
            service_value: proposal.service_value,
            quantity: proposal.quantity,
            trip_expenses_value: proposal.trip_expenses_value,
            price_terms: proposal.price_terms,
            is_ds_construction: proposal.is_ds_construction ? 1 : 0
        }
    })

    const onSubmit = values => {
        setLoading(true)
        updateProposal(proposal.id, values)
            .then(res => {
                message.success(res.data.message)
                reload()
            })
            .catch(error => handleError(error, setLoading))
    }

    const isInspection = proposal.format_version.business_scope === 'inspeccion'

    return (
        <React.Fragment>
            <Modal isOpen={visible} size='md'>
                <ModalHeader toggle={onClose}>Editar Propuesta {proposal.sku}</ModalHeader>
                <ModalBody>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <Form.Group>
                            <Form.Label>Fecha Elaboración</Form.Label>
                            <Form.Control 
                                type="date"
                                {...register('elaborated_at', {required:true})}
                            />
                            { errors.elaborated_at && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
                        </Form.Group>
                        <Row>
                            <Form.Group as={Col} sm="8">
                                <Form.Label>Valor por Unidad <span className='text-danger'>*</span></Form.Label>
                                <Form.Control 
                                    {...register('service_value', { required:true })}
                                />
                                { errors.service_value && <Form.Text className='text-warning'>Este campo es requerido y debe ser númerico</Form.Text> }
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label>Ctd <span className='text-danger'>*</span></Form.Label>
                                <Form.Control 
                                    {...register('quantity', { required:true })}
                                />
                                { errors.quantity && <Form.Text className='text-warning'>Este campo es requerido y debe ser númerico</Form.Text> }
                            </Form.Group>
                        </Row>
                        <Row>
                            <Form.Group as={Col}>
                                <Form.Label>Valor del Servicio</Form.Label>
                                <Form.Control value={currency(round(parseInt(watch('service_value'))*parseInt(watch('quantity')),-1))} disabled />
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label>Total { isInspection ? 'Gastos de Viaje' : 'Gastos de Transporte Gravado' } <small>(opcional)</small></Form.Label>
                                <Form.Control 
                                    {...register('trip_expenses_value')}
                                />
                                { errors.trip_expenses_value && <Form.Text className='text-warning'>Este campo es requerido y debe ser númerico</Form.Text> }
                            </Form.Group>
                        </Row>
                        <Form.Group>
                            <Form.Label>Valor Total</Form.Label>
                            <Form.Control value={currency((parseInt(watch('service_value'))*parseInt(watch('quantity'))) + parseInt(watch('trip_expenses_value')))} disabled />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Términos y condiciones del precio <span className='text-danger'>*</span></Form.Label>
                            <Form.Control 
                                {...register('price_terms', { required:true })}
                                placeholder='Escriba aquí...'
                                as="textarea"
                                style={{ height: '200px' }}
                            />
                            { errors.price_terms && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
                        </Form.Group>
                        { isInspection && (
                            <Form.Group>
                                <Form.Label>¿Es Dominguez Sanchez SAS el constructor?</Form.Label>
                                <Form.Control
                                    as="select"
                                    {...register('is_ds_construction')}
                                >
                                    <option value="">:: Seleccione una opción ::</option>
                                    <option value={1}>Sí</option>
                                    <option value={0}>No</option>
                                </Form.Control>
                            </Form.Group>
                        )}
                        <Button color="primary" type="submit" className='mt-15' disabled={loading}>
                            {loading && <Spinner animation='border' size="sm" />}{" "}
                            Actualizar Propuesta
                        </Button>
                    </Form>
                </ModalBody>
            </Modal>
        </React.Fragment>
    )
}