import React, { useState, useEffect } from 'react'
import { Button } from 'reactstrap'
import { Form } from 'react-bootstrap'

import ServiceEvidenceTable from './partials/ServiceEvidenceTable'
import EvidenceImagesSliderModal from './partials/EvidenceImagesSliderModal'
import RelatedEvidenceModal from '../Services/partials/RelatedEvidenceModal'
import ListTopBar from '../../components/ListTopBar'

import { getEvidenceTemplates } from '../EvidenceTemplates/services'
import { getEvidence } from './services'
import { handleError } from '../../helpers'

export default function ServiceEvidenceTab({ service, onlyNonCompliant, relatedService = null }) {
	const [evidenceTemplates, setEvidenceTemplates] = useState(null)
	const [evidence, setEvidence] = useState(null)
	const [selectedType, setSelectedType] = useState('document')
	const [filterType, setFilterType] = useState('name')
	const [filterValue, setFilterValue] = useState('')
	const [showSliderModal, setShowSliderModal] = useState(false)
	const [showRelatedEvidenceModal, setShowRelatedEvidenceModal] = useState(false)

	const verificationList = service.verification_list
	
	const filterListId = !onlyNonCompliant ? service.verification_list.id : service.initial_service.verification_list.id
	const filterRequired = !onlyNonCompliant ? 'related_verification_list_id' : 'related_non_compliant_id'

	useEffect(() => {
		!evidenceTemplates && getEvidenceTemplates({
			[`filter[${filterRequired}]`]: filterListId,
			'filter[type]': selectedType,
			[`filter[${filterType}]`]: filterValue,
			sort: 'item'
		})
			.then(res => setEvidenceTemplates(res.data.data))
			.catch(error => handleError(error))
	}, [ evidenceTemplates, filterListId, selectedType, filterType, filterValue, filterRequired ])

	useEffect(() => {
		!evidence && evidenceTemplates && getEvidence({
			'filter[verification_list_id]': verificationList.id,
		})
			.then(res => setEvidence(res.data.data))
			.catch(error => handleError(error))
	}, [ evidence, evidenceTemplates, verificationList ])

	return (
		<React.Fragment>
			<ListTopBar
				filterType={filterType}
				setFilterType={setFilterType}
				filterValue={filterValue}
				setFilterValue={setFilterValue}
				title={(
					<Form.Control
						as="select"
						value={selectedType}
						onChange={e => {
							setSelectedType(e.target.value)
							setEvidenceTemplates(null)
						}}
						style={{ width: '150px', marginTop: 10 }}
					>
						<option value="document">Ver Documentos</option>
						<option value="photo">Ver Fotografías</option>
					</Form.Control>
				)}
				options={[
					{ label: 'Buscar por nombre', value: 'name' },
					{ label: 'Buscar por item', value: 'item' },
				]}
				reload={() => setEvidenceTemplates(null)}
				className="simple mt-20"
				ctaText="Carrete de Fotos"
				handleCtaClick={() => setShowSliderModal(true)}
			/>
			<ServiceEvidenceTable 
				evidenceTemplates={evidenceTemplates}
				evidence={evidence}
				reload={() => { setEvidenceTemplates(null) }}
				reloadEvidence={() => { setEvidence(null) }}
				verificationListId={verificationList.id}
				relatedService={relatedService}
			/>
			{ service.related_service && (
				<Button className='mt-10' color='primary' outline onClick={() => setShowRelatedEvidenceModal(true)}>
					Evidencia del Servicio Relacionado
				</Button>
			)}
			{ showSliderModal && (
				<EvidenceImagesSliderModal 
					visible
					onClose={() => setShowSliderModal(false)}
					evidence={evidence}
				/>
			)}
			{ showRelatedEvidenceModal && (
				<RelatedEvidenceModal 
					visible
					onClose={() => setShowRelatedEvidenceModal(false)}
					relatedService={service.related_service}
					currentService={service}
					reloadEvidence={() => { setEvidence(null) }}
				/>
			)}
		</React.Fragment>
	)
}