import React, { useState, useEffect } from 'react'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'

import BranchesTable from './partials/BranchesTable'
import NewBranchModal from './partials/NewBranchModal'

import { getBranches } from './services'
import { handleError, parsePagination } from '../../helpers'

export default function CompanyBranchesModal({ visible, onClose, company }) {
	const [branches, setBranches] = useState(null)
	const [showNewModal, setShowNewModal] = useState(false)
	const [pagination, setPagination] = useState({page: 1, per_page: 15, total: 0})

	useEffect(() => {
		!branches && getBranches({ 
			...pagination,
			'filter[company_id]': company.id,
			include: 'instalationType'
		})
			.then(res => {
				setBranches(res.data.data)
				setPagination(parsePagination(res))
			})
			.catch(error => handleError(error))
	}, [branches, company, pagination])

	return (
		<React.Fragment>
			<Modal isOpen={visible} size="lg">
				<ModalHeader toggle={onClose}>Instalaciones de Cliente</ModalHeader>
				<ModalBody>
					<BranchesTable
						branches={branches}
						reload={() => setBranches(null)}
						pagination={pagination}
						setPagination={setPagination}
					/>
					<Button color="primary" onClick={() => setShowNewModal(true)} className='mt-10'>Agregar Nueva</Button>
				</ModalBody>
			</Modal>
			{ showNewModal && (
				<NewBranchModal 
					visible
					onClose={() => setShowNewModal(false)}
					reload={() => setBranches(null)} 
					companyId={company.id}
				/>
			)}
		</React.Fragment>
	)
}