import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Card, CardBody, Spinner } from 'reactstrap'
import { Tabs } from 'antd'

import ReportProfileTopBar from './partials/ReportProfileTopBar'
import ServiceProfileDetails from '../Services/partials/ServiceProfileDetails'
import InternetRequired from '../Offline/partials/InternetRequired'

import ServiceReportTab from './ServiceReportTab'
import ServiceVeredictTab from '../ServiceVeredicts/ServiceVeredictTab'
import CertificateTab from '../Certificates/CertificateTab'
import DecisionNoticeTab from '../DecisionNotices/DecisionNoticeTab'
import RecertificationProposalTab from '../Proposals/RecertificationProposalTab'
import ReviewLogbookVeredictsTab from '../ReviewLogbooks/ReviewLogbookVeredictsTab.js'

import { getServiceReport } from './services'
import { handleError } from '../../helpers'

export default function ServiceReportProfile(props) {
	const { isConnected } = useSelector(state => state.offline)
	const id = props.match.params.id
	const user = useSelector(state => state.auth.user)
	const [serviceReport, setServiceReport] = useState(null)
	const [currentTab, setCurrentTab] = useState('informe')

	useEffect(() => {
		!serviceReport && isConnected && getServiceReport(id, {
			include: 'serviceReview.servicePlan.serviceInspector.service.proposal.inquiry.branchSpec.branch.company,serviceReview.servicePlan.serviceInspector.service.proposal.inquiry.employee,serviceReview.servicePlan.serviceInspector.user,serviceReview.tankSpecs,serviceReview.islandSpecs,serviceReview.reviewEquipments,serviceVeredict.certificate,serviceVeredict.decisionNotice,reportEvidence,formatVersion,serviceReview.reviewLogbooks',
		})
			.then(res => setServiceReport(res.data.data))
			.catch(error => handleError(error))
	}, [ serviceReport, id, isConnected ])

	if(!isConnected) return <InternetRequired />

	if(!serviceReport) return <Spinner />

	const reload = () => setServiceReport(null)

	const generalItems = [
		{
			key: 'informe',
			label: '1. Informe de Inspección',
			children: <ServiceReportTab serviceReport={serviceReport} reload={reload} />,
		},
		{
			key: 'revision',
			label: '2. Revisión y Decisión',
			disabled: user.role.includes('inspector'),
			children: <ServiceVeredictTab serviceReport={serviceReport} reload={reload} />,
		},
	]

	const inspectionItems = generalItems.concat([
		{
			key: 'certificado',
			label: '3. Certificado de Inspección',
			disabled: (user.role.includes('inspector') || !serviceReport.service_veredict),
			children: <CertificateTab serviceReport={serviceReport} reload={reload} />,
		},
		{
			key: 'carta',
			label: '4. Comunicado de Decisión',
			disabled: (user.role.includes('inspector') || serviceReport.is_committee_compliant === null),
			children: <DecisionNoticeTab serviceReport={serviceReport} reload={reload} />,
		},
		{
			key: 'recertificacion',
			label: '5. Propuesta de Recertificación',
			disabled: (user.role.includes('inspector') || !(serviceReport.service_veredict && serviceReport.service_veredict.decision_notice && user.role === 'admin')),
			children: <RecertificationProposalTab serviceReport={serviceReport} reload={reload} />,
		},
	])

	const testItems = generalItems.concat([
		{
			key: 'bitacoras',
			label: '3. Resultado de Bitacoras',
			disabled: user.role.includes('inspector'),
			children: <ReviewLogbookVeredictsTab serviceReport={serviceReport} reload={reload} />
		},
		{
			key: 'certificado',
			label: '4. Certificado de Inspección',
			disabled: user.role.includes('inspector'),
			children: <CertificateTab serviceReport={serviceReport} reload={reload} />,
		},
		{
			key: 'carta',
			label: '5. Comunicado de Decisión',
			disabled: (user.role.includes('inspector') || !serviceReport.service_veredict || !serviceReport.service_veredict.certificate),
			children: <DecisionNoticeTab serviceReport={serviceReport} reload={reload} />,
		},
	])

	return (
		<React.Fragment>
			<ReportProfileTopBar serviceReport={serviceReport} reload={reload} />
			<Card>
				<CardBody>
					<Tabs 
						tabPosition='left' 
						type="card"
						defaultActiveKey={currentTab}
						activeKey={currentTab}
						onChange={key => setCurrentTab(key)}
						items={serviceReport.format_version.business_scope === 'inspeccion' ? inspectionItems : testItems}
					/>
				</CardBody>
			</Card>
			<ServiceProfileDetails 
				service={serviceReport.service_review.service_plan.service_inspector.service} 
				reload={() => setServiceReport(null)}
			/>
		</React.Fragment>
	)
}