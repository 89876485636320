import React from 'react'
import { useForm } from 'react-hook-form'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { Form, Row, Col } from 'react-bootstrap'
import { message } from 'antd'

import { updateRetryNotice } from '../services'
import { handleError } from '../../../helpers'

export default function EditRetryNoticeModal({ visible, onClose, retryNotice, reload }) {
	const { register, handleSubmit, formState: { errors } } = useForm({
		defaultValues: {
			service_value: retryNotice.service_value,
			trip_expenses_value: retryNotice.trip_expenses_value,
			price_terms: retryNotice.price_terms,
		}
	})

	const onSubmit = values => {
		updateRetryNotice(retryNotice.id, values)
			.then(res => {
				message.success(res.data.message)
				reload()
			})
			.catch(error => handleError(error))
	}

	return (
		<React.Fragment>
			<Modal isOpen={visible}>
				<ModalHeader toggle={onClose}>Editar Comunicado de Inspección de Cierre</ModalHeader>
				<ModalBody>
					<Form onSubmit={handleSubmit(onSubmit)}>
						<Row>
							<Form.Group as={Col}>
								<Form.Label>Valor Servicio Inspección <small>(opcional / si aplica)</small></Form.Label>
								<Form.Control 
									{...register('service_value')}
									type="number"
								/>
								{ errors.service_value && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
							</Form.Group>
							<Form.Group as={Col}>
								<Form.Label>Total Gastos de Viaje <small>(opcional / si aplica)</small></Form.Label>
								<Form.Control 
									{...register('trip_expenses_value')}
									type="number"
								/>
							</Form.Group>
						</Row>
						<Form.Group>
							<Form.Label>Términos del Precio <small>(opcional / si aplica)</small></Form.Label>
							<Form.Control 
								as="textarea"
								{...register('price_terms')}
								style={{ height: '70px' }}
								placeholder='Escriba aquí...'
								defaultValue={`El precio de gastos de viaje puede variar, en el caso que por conveniencia del cliente asume directamente el costo de tiquetes aéreos. 
								En caso de que el cliente asuma el costo de tiquetes se realizará la variación en el total del servicio en la correspondiente cuenta de cobro/factura.
								Los costos del tiquete están sujetos a variaciones de acuerdo con la programación que se realice.
								El precio total por cancelar es el Total del servicio de inspección más IVA`}
							/>
						</Form.Group>
						<Button color="primary" type="submit">Actualizar Comunicado</Button>
					</Form>
				</ModalBody>
			</Modal>
		</React.Fragment>
	)
}