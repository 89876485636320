import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Table, Tooltip, Divider, Popconfirm, message, Empty } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faStore, faTrash } from '@fortawesome/free-solid-svg-icons'

import EvidenceInstalationTypesModal from './EvidenceInstalationTypesModal'

import { deleteEvidenceTemplate } from '../services'
import { handleError } from '../../../helpers'
import EditEvidenceTemplateModal from './EditEvidenceTemplateModal'

export default function EvidenceTemplatesTable({ evidenceTemplates, reload }) {
	const currentUser = useSelector(state => state.auth.user)
	const [instalationTypesModal, setInstalationTypesModal] = useState(null)
	const [editModal, setEditModal] = useState(null)
	
	const columns = [
		{
			title: 'No Evidencia',
			dataIndex: 'item',
		},
		{
			title: 'Tipo',
			dataIndex: 'type_name',
		},
		{
			title: 'Descripción',
			dataIndex: 'name'
		},
		{
			title: 'Acciones',
			width: '150px',
			render: (t, r) => (
				<React.Fragment>
					<Tooltip title="Tipos de Instalación">
						<FontAwesomeIcon 
							icon={faStore} 
							className='text-link' 
							onClick={() => setInstalationTypesModal(r)}
						/>
					</Tooltip>
					{ currentUser.role === 'admin' && (
						<React.Fragment>
							<Divider type="vertical" />
							<Tooltip title="Editar">
								<FontAwesomeIcon
									icon={faEdit}
									className="text-link"
									onClick={() => setEditModal(r)}
								/>
							</Tooltip>
							<Divider type="vertical" />
							<Tooltip title="Eliminar">
								<Popconfirm 
									title="¿Desea eliminar la plantilla?"
									okText="Eliminar"
									cancelText="Cancelar"
									onConfirm={() => handleDelete(r.id)}
									okButtonProps={{ danger:true }}
								>
									<FontAwesomeIcon className="text-link" icon={faTrash} />
								</Popconfirm>
							</Tooltip>
						</React.Fragment>
					)}
				</React.Fragment>
			)
		}
	]

	const handleDelete = id => {
		deleteEvidenceTemplate(id)
			.then(res => {
				message.success(res.data.message)
				reload()
			})
			.catch(error => handleError(error))
	}

	return (
		<React.Fragment>
			<Table 
				dataSource={evidenceTemplates}
				columns={columns}
				rowKey={r => r.id}
				className='mt-30'
				size="small"
				locale={{ emptyText: <Empty description="No tiene plantillas creadas" imageStyle={{ height: '70px' }} /> }}
				loading={!evidenceTemplates}
			/>
			{ instalationTypesModal && (
				<EvidenceInstalationTypesModal 
					visible
					onClose={() => setInstalationTypesModal(null)}
					evidenceTemplate={instalationTypesModal}
					reload={newRecord => {
						reload()
						setInstalationTypesModal(newRecord)
					}}
				/>
			)}
			{ editModal && (
				<EditEvidenceTemplateModal 
					visible
					onClose={() => setEditModal(null)}
					reload={reload}
					evidenceTemplate={editModal}
				/>
			)}
		</React.Fragment>
	)
}