import React from 'react'

import PROPOSAL_TEMPLATE_PRU01 from './templates/PROPOSAL_TEMPLATE_PRU01';
import PROPOSAL_TEMPLATE_INS01 from './templates/PROPOSAL_TEMPLATE_INS01';
import RECERT_PROPOSAL_TEMPLATE_INS01 from './templates/RECERT_PROPOSAL_TEMPLATE_INS01';

import DocumentTemplate404 from '../../../components/DocumentTemplate404';

export default function ProposalDocument({ data, isRecertification }) {	
	switch (data.format_template) {
		case 'PROPOSAL_TEMPLATE_INS01':
			return !isRecertification ? <PROPOSAL_TEMPLATE_INS01 data={data} /> : <RECERT_PROPOSAL_TEMPLATE_INS01 data={data} />

		case 'PROPOSAL_TEMPLATE_PRU01':
			return <PROPOSAL_TEMPLATE_PRU01 data={data} />
	
		default:
			return <DocumentTemplate404 />
	}

}