import dewarApi from '../../services/dewarApi'

export const getReportDefaultEvidence = params => (
	new Promise((resolve, reject) => {
		dewarApi()
			.get('/report_default_evidence', { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const getSingleReportDefaultEvidence = (id, params) => (
	new Promise((resolve, reject) => {
		dewarApi()
			.get(`/report_default_evidence/${id}`, { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const storeReportDefaultEvidence = data => (
	new Promise((resolve, reject) => {
		dewarApi()
			.post('/report_default_evidence', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const updateReportDefaultEvidence = (id, data) => (
	new Promise((resolve, reject) => {
		dewarApi()
			.put(`/report_default_evidence/${id}`, data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const deleteReportDefaultEvidence = id => (
	new Promise((resolve, reject) => {
		dewarApi()
			.delete(`/report_default_evidence/${id}`)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)