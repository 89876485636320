import React from 'react'
import { useForm } from 'react-hook-form'
import { Form } from 'react-bootstrap'
import { Button } from 'reactstrap'
import { message } from 'antd'

import InstalationTypeForm from './InstalationTypeForm'

import { storeInstalationType } from '../services'
import { handleError } from '../../../helpers'

export default function NewInstalationTypeForm({ reload }) {
	const { register, handleSubmit, formState: { errors }, reset } = useForm()

	const onSubmit = values => {
		storeInstalationType(values)
			.then(res => {
				message.success(res.data.message)
				reload()
				reset()
			})
			.catch(error => handleError(error))
	}

	return (
		<React.Fragment>
			<Form onSubmit={handleSubmit(onSubmit)}>
				<InstalationTypeForm 
					register={register} 
					errors={errors} 
				/>
				<Button type="submit" color="primary">Crear Tipo de Instalación</Button>
			</Form>
		</React.Fragment>
	)
}