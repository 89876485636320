import React from 'react'
import { Row, Col, InputGroup, Form } from 'react-bootstrap'

import { SectionContent, SectionTitle } from '../../../components/WizardComponents'

export default function HasHighVolumeWizardForm({ form, setForm }) {
	return (
		<React.Fragment>
			<SectionTitle>Información para Plantas de almacenamiento o Mayoristas o Grandes consumidores:</SectionTitle>
			<SectionContent className='pt-10 pb-0'>
				<Row>
					<InputGroup as={Col}>
						<InputGroup.Text>Número de brazos de llenado:</InputGroup.Text>
						<Form.Control 
							type="number"
							value={form.branch_spec.total_brazos}
							onChange={e => setForm({ 
								...form, branch_spec: { ...form.branch_spec, total_brazos: e.target.value } 
							})}
							placeholder='Escriba aquí...'
						/>
					</InputGroup>
					<InputGroup as={Col}>
						<InputGroup.Text>Número de líneas de recepción:</InputGroup.Text>
						<Form.Control 
							type="number"
							value={form.branch_spec.total_lineas_recepcion}
							onChange={e => setForm({ 
								...form, branch_spec: { ...form.branch_spec, total_lineas_recepcion: e.target.value } 
							})}
							placeholder='Escriba aquí...'
						/>
					</InputGroup>
				</Row>
				<Row>
					<InputGroup as={Col}>
						<InputGroup.Text>Número de islas de recepción:</InputGroup.Text>
						<Form.Control 
							type="number"
							value={form.branch_spec.total_islas_recepcion}
							onChange={e => setForm({ 
								...form, branch_spec: { ...form.branch_spec, total_islas_recepcion: e.target.value } 
							})}
							placeholder='Escriba aquí...'
						/>
					</InputGroup>
					<InputGroup as={Col}>
						<InputGroup.Text>Número de recintos para tanques aéreos:</InputGroup.Text>
						<Form.Control 
							type="number"
							value={form.branch_spec.total_recintos}
							onChange={e => setForm({ 
								...form, branch_spec: { ...form.branch_spec, total_recintos: e.target.value } 
							})}
							placeholder='Escriba aquí...'
						/>
					</InputGroup>
				</Row>
			</SectionContent>
		</React.Fragment>
	)
}