import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { DatePicker, Divider, message, Result, TimePicker } from 'antd'
import { Form } from 'react-bootstrap'
import moment from 'moment'

import UploadAcceptanceAttachment from './UploadAcceptanceAttachment'

import { updateProposal } from '../services'
import { handleError } from '../../../helpers'

export default function ProposalAcceptanceModal({ visible, onClose, proposal, reload }) {
	const [acceptedAtDay, setAcceptedAtDay] = useState(moment())
	const [acceptedAtTime, setAcceptedAtTime] = useState(moment())
	const [attachmentUri, setAttachmentUri] = useState('')

	const onSubmit = () => {
		updateProposal(proposal.id, { 
			accepted_at: `${acceptedAtDay.format('YYYY-MM-DD')} ${acceptedAtTime.format('HH:mm')}`,
			accept_attachment_uri: attachmentUri
		})
			.then(res => {
				message.success(res.data.message)
				reload()
			})
			.catch(error => handleError(error))
	}

	const onResubmit = newAttachmentUri => {		
		updateProposal(proposal.id, {
			accept_attachment_uri: newAttachmentUri
		})
			.then(res => {
				message.success(res.data.message)
				reload()
			})
			.catch(error => handleError(error))
	}
	
	return (
		<React.Fragment>
			<Modal isOpen={visible}>
				<ModalHeader toggle={onClose}>
					Aceptación Propuesta {proposal.sku}
				</ModalHeader>
				<ModalBody>
					{ !proposal.accepted_at ? (
						<React.Fragment>
							<Form.Group className='mb-10'>
								<Form.Label>Fecha de Aceptación</Form.Label>
								<div>
									<DatePicker 
										value={acceptedAtDay}
										onChange={date => setAcceptedAtDay(date)}
										disabledTime={false}
										className="mr-10"
									/>
									<TimePicker 
										value={acceptedAtTime}
										onChange={time => setAcceptedAtTime(time)}
										format="hh:mm a"
									/>
								</div>
							</Form.Group>
							<Form.Group>
								<Form.Label>Subir Soporte de Aceptación</Form.Label>
								<UploadAcceptanceAttachment 
									setAttachmentUri={setAttachmentUri}
									proposalId={proposal.id}
								/>
							</Form.Group>
							<Button color="primary" onClick={onSubmit} className='mt-20' disabled={!attachmentUri}>
								Registrar Aceptación
							</Button>
						</React.Fragment>
					) : (
						<Result 
							status="success" 
							title={`Propuesta aceptada el ${moment(proposal.accepted_at).format('YYYY-MM-DD hh:mm a')}`} 
							extra={(
								<React.Fragment>
									<Link to={`/proposals/acceptance_preview/${proposal.id}`}>
										<Button color="primary">Ver Aceptación</Button>
									</Link>
									{ proposal.accept_attachment_uri && (
										<React.Fragment>
											<Divider type="vertical" />
											<a href={`${proposal.accept_attachment_uri}?cache=${Date.now()}`}target="_blank" rel="noreferrer">
												<Button color="primary">Ver Adjunto</Button>
											</a>
											<Divider type="horizontal" />
											<Form.Label>Reemplazar Soporte de Aceptación</Form.Label>
											<Form.Text className='text-muted'>Esta acción no se puede revertir. Se perderá por completo el archivo anterior, descarguelo primero en caso de requerir copia del mismo.</Form.Text>
											<UploadAcceptanceAttachment 
												setAttachmentUri={onResubmit}
												proposalId={proposal.id}
											/>
										</React.Fragment>
									)}
								</React.Fragment>
							)}
						/>
					)}
				</ModalBody>
			</Modal>
		</React.Fragment>
	)
}