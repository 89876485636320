import React, { useEffect, useState } from 'react'
import { Modal, ModalHeader, ModalBody, Button } from 'reactstrap'
import { Form, Spinner } from 'react-bootstrap'
import { message } from 'antd'

import { getServices, updateService } from '../services'
import { handleError } from '../../../helpers'

export default function RelatedServiceModal({ visible, onClose, serviceId, companyId, reload }) {
    const [services, setServices] = useState(null)
    const [selectedServiceId, setSelectedServiceId] = useState('')
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        !services && getServices({ 
            'filter[proposal.inquiry.branchSpec.branch.company.id]': companyId
        })
            .then(res => setServices(res.data.data))
            .catch(error => handleError(error))
    }, [ services, companyId ])

    const onSubmit = () => {
        if(!selectedServiceId)
            return message.warning('Debe seleccionar un servicio')

        setLoading(true)

        updateService(serviceId, {
            related_service_id: selectedServiceId
        })
            .then(res => {
                message.success(res.data.message)
                reload()
            })
            .catch(error => handleError(error, setLoading))
    }

    return (
        <React.Fragment>
            <Modal isOpen={visible}>
                <ModalHeader toggle={onClose}>Asignar Servicio Relacionado</ModalHeader>
                <ModalBody>
                    <Form.Group className='form-group'>
                        <Form.Label>ID del Servicio <span className='text-danger'>*</span></Form.Label>
                        <Form.Control
                            as="select"
                            value={selectedServiceId}
                            onChange={e => setSelectedServiceId(e.target.value)}
                        >
                            <option value="">:: Seleccione un servicio ::</option>
                            { services ? (
                                <React.Fragment>
                                    { services.length > 0 ? (
                                        <React.Fragment>
                                            { services.filter(s => s.id !== serviceId).map(service => (
                                                <option key={service.id} value={service.id}>
                                                    Servicio #{service.id}
                                                </option>
                                            )) }
                                        </React.Fragment>
                                    ) : (
                                        <option disabled>No se encontraron servicios</option>
                                    )}
                                </React.Fragment>
                            ) : (
                                <option disabled>Cargando...</option>
                            )}
                        </Form.Control>
                    </Form.Group>
                    <Button color='primary' onClick={onSubmit} disabled={loading}>
                        { loading && <Spinner animation='border' size='sm' className='mr-5' /> }
                        Asignar Servicio
                    </Button>
                </ModalBody>
            </Modal>
        </React.Fragment>
    )
}