import dewarApi, { getDomain } from '../../services/dewarApi'

export const getCompanies = params => (
	new Promise((resolve, reject) => {
		dewarApi()
			.get('/companies', { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const getCompany = (id, params) => (
	new Promise((resolve, reject) => {
		dewarApi()
			.get(`/companies/${id}`, { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const storeCompany = data => (
	new Promise((resolve, reject) => {
		dewarApi()
			.post('/companies', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const updateCompany = (id, data) => (
	new Promise((resolve, reject) => {
		dewarApi()
			.put(`/companies/${id}`, data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const deleteCompany = id => (
	new Promise((resolve, reject) => {
		dewarApi()
			.delete(`/companies/${id}`)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const searchCompanys = async (name, token) => (
	fetch( getDomain()+`/companies?filter[name]=${name}`, {
		headers: {'Authorization': `Bearer ${token}`}
	})
		.then((response) => response.json())
		.then((res) =>
			res.data.map(company => ({
				label: company.name,
				value: company.id,
			})),
		)
)