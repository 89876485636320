import dewarApi from '../../services/dewarApi'

export const getCompanyEmails = params => (
	new Promise((resolve, reject) => {
		dewarApi()
			.get('/company_emails', { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const getCompanyEmail = (id, params) => (
	new Promise((resolve, reject) => {
		dewarApi()
			.get(`/company_emails/${id}`, { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const storeCompanyEmail = data => (
	new Promise((resolve, reject) => {
		dewarApi()
			.post('/company_emails', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const updateCompanyEmail = (id, data) => (
	new Promise((resolve, reject) => {
		dewarApi()
			.put(`/company_emails/${id}`, data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const deleteCompanyEmail = id => (
	new Promise((resolve, reject) => {
		dewarApi()
			.delete(`/company_emails/${id}`)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)