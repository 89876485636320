import dewarApi, { getDomain } from '../../services/dewarApi'

export const getPreInvoices = params => (
	new Promise((resolve, reject) => {
		dewarApi()
			.get('/pre_invoices', { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const getPreInvoice = (id, params) => (
	new Promise((resolve, reject) => {
		dewarApi()
			.get(`/pre_invoices/${id}`, { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const storePreInvoice = data => (
	new Promise((resolve, reject) => {
		dewarApi()
			.post('/pre_invoices', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const updatePreInvoice = (id, data) => (
	new Promise((resolve, reject) => {
		dewarApi()
			.put(`/pre_invoices/${id}`, data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const deletePreInvoice = id => (
	new Promise((resolve, reject) => {
		dewarApi()
			.delete(`/pre_invoices/${id}`)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const publicGetPreInvoice = (id, params) => (
	new Promise((resolve, reject) => {
		dewarApi()
			.get(`/public/pre_invoices/${id}`, { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const searchPreInvoices = async (name, token) => (
	fetch( getDomain()+`/pre_invoices?filter[name]=${name}`, {
		headers: {'Authorization': `Bearer ${token}`}
	})
		.then((response) => response.json())
		.then((res) =>
			res.data.map(pre_invoice => ({
				label: pre_invoice.name,
				value: pre_invoice.id,
			})),
		)
)

export const notifyPreInvoice = id => (
	new Promise((resolve, reject) => {
		dewarApi()
			.post(`/pre_invoices/${id}/notify`)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)