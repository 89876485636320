import React from 'react'
import styled from 'styled-components'
import moment from 'moment'

import DocumentHeader from '../../../../components/DocumentHeader'
import { currency } from '../../../../helpers'

export default function PROPOSAL_TEMPLATE_PRU01({ data }) {

    const branch = data.inquiry.branch_spec.branch
    const company = branch.company

    return (
        <Container>
            <DocumentHeader
                title="PROPUESTA COMERCIAL"
                version={data.format_version ? data.format_version.sku : '_______'}
                expireAt={data.format_version ? data.format_version.expire_at : '_________'}
                proposalSku={data.id ? data.sku : null}
            />
            <div className='text-right mb-15'>
                <strong>Elaborada el:</strong> {data.elaborated_at ? moment(data.elaborated_at).format('DD-MMM-YYYY').toUpperCase() : <em>Sin especificar</em>}
            </div>
            <p className='mb-30 bold'>
                Señores<br />
                {company.legal_owner_name} <br />
                Representante Legal <br/>
                {company.name}<br />
                {branch.name}<br />
                {branch.address_line}<br />
                {branch.address_state}, {branch.address_city}<br />
            </p>
            <h5>PRESENTACIÓN</h5>
            <p>En DEWAR SAS contamos con acreditación ONAC, vigente a la fecha, con código de acreditación 21-OIN-008 bajo la norma ISO/IEC 17020:2012. <br /><br />
            Agradecemos, haber elegido a DEWAR como el Organismo de Inspección de su instalación y les garantizamos que además de la competencia, capacidad y experiencia para realizar procesos de inspección de instalaciones y de pruebas de hermeticidad de tanques y tuberías, contamos con calidad, eficacia, independencia, transparencia y confidencialidad en los procesos que nos sean asignados.</p>
            <h5>SIETE RAZONES PARA HACER LAS PRUEBAS CON DEWAR:</h5>
            <ol>
                <li>Somos la primera empresa en Colombia en haber obtenido la acreditación ONAC de las pruebas de hermeticidad bajo la norma ISO/IEC 17020:2012</li>
                <li>Nuestras pruebas de hermeticidad cumplen con los requisitos especificados en los numeral 5.6.2 literales {data.inquiry.test_details.service_name.includes('Tanques y Tuberías') ? 'm y n' : data.inquiry.test_details.service_name.includes('Tanques') ? 'm' : 'n'} de la Resolución No. 40198 del 24 de junio de 2021, expedida por Ministerio de Minas y Energía.</li>
                <li>Los certificados e informes emitidos cumplen los requerimientos de la Dirección de Hidrocarburos de Colombia, numeral 5.6.2 literal p de la Resolución No. 40198 del 24 de junio de 2021</li>
                <li>Nuestras pruebas son un mecanismo para prevenir fugas y pérdidas de combustible ocasionadas por el ataque químico, la corrosión y las presiones a que son sometidos los tanques de combustible y su tubería y que generan debilitamiento en sus paredes y estructura. Así mismo, preservan la calidad del combustible siguiendo la NTC 6032 Buenas Prácticas de Manejo para el Biodiesel y NTC  6198 Mezclas Gasolina – Alcohol y evitar atmósferas explosivas alrededor de los equipos de su estación.</li>
                <li>Nuestros equipos son calibrados por laboratorios acreditados por ONAC para garantizar  la alta precisión, las pruebas son no destructivas y se ajustan a las presiones de operación de los tanques y tuberías dadas por los fabricantes y el reglamento técnico.</li>
                <li>Somos reconocidos en el sector de hidrocarburos por nuestra competencia, capacidad y experiencia, contamos eficiencia, buen criterio técnico y cumplimiento de los principios de independencia, imparcialidad y confidencialidad. </li>
                <li>Contamos con un sistema de información diseñado exclusivamente para el manejo de la información del proceso de forma clara, precisa, oportuna y segura. Los Clientes tendrán acceso al “PORTAL DE CLIENTES” y donde podrán consultar el informe del proceso, así como el certificado correspondiente a las pruebas de hermeticidad realizadas.</li>
            </ol>
            <p>De acuerdo con la Solicitud de Servicio diligenciada por su empresa y recibida en DEWAR, la cual ha sido revisada y aprobada en relación con nuestro alcance de inspección, presentamos la propuesta comercial para la prestación del servicio de pruebas de hermeticidad y recuerde que en el momento que lo requiera puede solicitarnos propuesta para realizar la inspección de su instalación.<br /> <br />
            Es parte integral de esta propuesta comercial “El Reglamento de Inspección” de DEWAR, el cual se adjunta a esta propuesta, para total conocimiento de las regulaciones del proceso, de los derechos y deberes de las partes, y de los requisitos de la inspección.  En el momento de aceptación de esta propuesta usted declarará conocer y aceptar este Reglamento, tal como se establece en la carta de su aceptación y de esta forma se establece un acuerdo legalmente ejecutable entre las partes. </p>
            <h5>1. PROPUESTA TÉCNICA</h5>
            <h5>1.1 OBJETIVO</h5>
            <p>Realizar <strong>{data.inquiry.test_details.service_name}</strong> para <strong>{branch.name}</strong>.</p>
            <h5>1.2 ALCANCE</h5>
            <p>DEWAR suministra personal calificado, la metodología y el procedimiento en cumplimiento con el reglamento técnico, para llevar a buen término el proceso de pruebas de hermeticidad, tal como corresponde a sus funciones y responsabilidades como Organismo de Inspección.	<br /><br />
            Para el proceso de pruebas de hermeticidad de tanques y tuberías en las siguientes instalaciones:</p>
            <table className='table all_borders'>
                <thead>
                    <tr>
                        <th>NOMBRE INSTALACIÓN</th>
                        <th>DIRECCIÓN</th>
                        <th>CIUDAD-MUNICIPIO</th>
                        <th>DEPARTAMENTO</th>
                        <th>TANQUES</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{branch.name}</td>
                        <td>{branch.address_line}</td>
                        <td>{branch.address_city}</td>
                        <td>{branch.address_state}</td>
                        <td>{data.inquiry.test_details.total_tanks}</td>
                    </tr>
                </tbody>
            </table>
            <h5>1.3 CRITERIO</h5>
            <p>DEWAR dando cumplimiento a los estándares establecidos en la NTC-ISO/IEC 17020, adelanta el proceso de inspección conforme con los requisitos especificados en el numeral 5.6.2 literal {data.inquiry.test_details.service_name.includes('Tanques y Tuberías') ? 'm y n' : data.inquiry.test_details.service_name.includes('Tanques') ? 'm' : 'n'} de la Resolución No. 40198 del 24 de junio de 2021, y de la Resolución No. 40405 del 24 de diciembre de 2020, expedidas por Ministerio de Minas y Energía; y numerales 21.5.3 y 27.7.3 de la NFPA 30 Código de Líquidos inflamables y combustibles.</p>
            <h5>2. PROCESO DE INSPECCIÓN – PRUEBA DE HERMETICIDAD</h5>
            <p>Una vez aceptada esta propuesta, se asignará el inspector según capacidades y competencia, además de los criterios de independencia e imparcialidad, y en la notificación de la inspección se informará la programación para realizar el proceso de inspección – prueba de hermeticidad.<br/><br/>
            Para la realización de las pruebas de hermeticidad, el Cliente debe enviar previo a la programación los siguientes documentos:
            </p>
            <ul>
                <li>Si se tiene, soporte documental que sustente las recomendaciones del fabricante sobre el proceso de pruebas de hermeticidad a tanque y tuberías; de lo contrario se seguirá lo establecido en la Resolución 40198 de 2021 del Ministerio de Minas y Energía.</li>
                <li>Información de los desfogues si se encuentran a más de 7 metros y/o para su acceso se requieren condiciones especiales, y si se cuenta con válvula para independizar el desfogue del tanque.</li>
                <li>Otros documentos y/o registros que considere pertinentes para la realización de la prueba.</li>
            </ul>
            <p>
                Estos documentos serán recibidos de forma digital, y serán manejados bajo los principios de confidencialidad. Si no son enviados oportunamente el servicio será realizado en cumplimiento de lo establecido en la Resolución 40198 de 2021.
                <br /><br />
                Para el día de la actividad de inspección es requerido que el Cliente cuente con la disponibilidad del personal encargado de atender la inspección y autorizado para el manejo de los equipos requeridos y que sea responsable frente al prendido y apagado del tablero eléctrico. Es preferible, más no requerido que el tanque a inspeccionar cuente con un llenado superior al 70%. 
                <br /><br />
                La prueba de hermeticidad inicia una vez se instala el accesorio con las mangueras para inyección de nitrógeno y la hora de prueba empezará a contar desde el momento en el que la presión del tanque se estabilice entre 2.5 - 3 psi o de acuerdo con las recomendaciones del fabricante. Durante el proceso de prueba, el tanque a inspeccionar se encontrará fuera de funcionamiento mínimo 2 horas aproximadamente. 
                <br /><br />
                Para la realización de la actividad de inspección, DEWAR suministrará todos los equipos e insumos de seguridad necesarios para el desarrollo de las pruebas, como son: Cinta de seguridad, conos, extintores, señalización, equipos de trabajo en alturas de requerirse como pueden ser escaleras, mosquetones, arnés y eslingas. De requerirse andamios, el Cliente debe asumir este costo y asegurar que se cuente con los mismos el día de las pruebas de hermeticidad. 
                <br /><br />
                Una vez se realice la prueba de hermeticidad, para determinar la conformidad del tanque y/o tubería es necesario se alcance la presurización y que esta se mantenga en cumplimiento del Reglamento técnico, el inspector se tomará un tiempo para estudiar sus registros. Para determinar la conformidad del tanque o tubería es necesario que en cumplimiento de los requisitos. Finalmente realizará la reunión de cierre de la inspección, donde entregará el acta de inspección. 
                <br /><br />
                El Reglamento Técnico no exige que la verificación de estado y funcionamiento de la tubería de desfogue sea realizada por un organismo de inspección acreditado, sin embargo, como servicio adicional DEWAR durante la prueba hermeticidad de los tanques y tuberías si es posible realizar de manera segura, se verifica la hermeticidad y funcionamiento de la tubería
                <br /><br />
                El inspector entregará los registros del proceso al Director Técnico para revisión, elaboración del informe y toma de decisión frente a la conformidad. El informe del proceso será enviado al Cliente ocho (8) días calendario después de realizada la inspección y de declararse la conformidad, se emitirá el certificado de inspección correspondiente.
                <br /><br />
                Si alguno de los tanques o tubería presenta resultado no conforme, el Cliente tendrá que realizar los correctivos necesarios y realizar una nueva solicitud, una vez sea presentada y aprobada la propuesta se realizará un nuevo proceso de inspección para evaluar la conformidad. El tanque o la tubería no conforme no se incluirá dentro del Certificado de Inspección.
                <br /><br />
                Las fechas consignadas en el certificado de inspección corresponden a los días en que se realizaron las pruebas, y el día que el certificado es emitido. 
            </p>
            <h5>3. VALOR AGREGADO</h5>
            <p>
                El prestigio empresarial de DEWAR, su posicionamiento, aceptación en el mercado y reconocimiento del CONOCIMIENTO Y EXPERIENCIA, como un Organismo especializado por más de 15 años en el sector de combustibles; son la mayor fortaleza y garantía para que las pruebas de hermeticidad sean realizadas con los mejores estándares de calidad y reducción de riesgos, cumplimiento cabal de las normas y de las regulaciones con la evidente generación real de valor. 
                <br /><br />
                DEWAR está comprometido con los principios empresariales de transparencia, imparcialidad, independencia y confidencialidad, desarrolla sus procedimientos bajo cumplimiento de estos principios y asigna su personal asegurando la alta experiencia, competencia técnica y desempeño profesional.
            </p>
            <h5>4.PROPUESTA ECONÓMICA</h5>
            <table className='table all_borders'>
                <thead>
                    <tr>
                        <th>ACTIVIDAD</th>
                        <th>CANTIDAD</th>
                        <th>VALOR UNITARIO</th>
                        <th>VALOR TOTAL</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className='bold' colSpan={3}>
                            {data.inquiry.test_details.service_name}{" "}{branch.name}{" "}{branch.address_city}, {branch.address_state}
                        </td>
                        <td>$ {currency(data.total)}</td>
                    </tr>
                    <tr>
                        <td>{branch.name}</td>
                        <td>{data.quantity}</td>
                        <td>$ {currency(data.service_value)}</td>
                        <td>$ {currency(data.service_value*data.quantity)}</td>
                    </tr>
                    { data.trip_expenses_value > 0 && (
                        <tr>
                            <td colSpan={3}>Gastos de Transporte</td>
                            <td>$ {currency(data.trip_expenses_value)}</td>
                        </tr>
                    )}
                    <tr>
                        <td colSpan={3} className='bold bg-light'>VALOR TOTAL ANTES DE IVA</td>
                        <td>$ {currency(data.total)}</td>
                    </tr>
                </tbody>
            </table>
            <p><span style={{ whiteSpace: 'pre-line' }}>{data.price_terms}</span></p>
            <h5>5. APROBACIÓN PROPUESTA Y FORMA DE PAGO </h5>
            <ul>
                <li>El Cliente debe conocer y acoger el Reglamento de Inspección.</li>
                <li>El Cliente deberá enviar firmada por el Representante Legal la carta de aceptación y/u orden de compra haciendo referencia al código de la propuesta aceptada.</li>
                <li>En el momento del envío de la carta de aceptación se acordará la programación, dependiendo de la fecha de pago del servicio y la disponibilidad del inspector.</li>
                <li>El envío de la notificación de inspección se debe poder realizar 5 días antes de la fecha de la visita a la instalación.</li>
                <li>En el momento de estar realizando el servicio, se presenta un tanque o tubería adicional, esto deberá ser aprobado por escrito por el personal autorizado de la instalación, y se reflejará el adicional en la factura correspondiente. </li>
                <li>En el momento que el servicio no se pueda llevar a cabo por omisión, información suministrada por el Cliente que no concuerda con la realidad de la instalación, o porque no se da cumplimiento a los términos de esta propuesta, sin motivo de incumplimiento por parte de DEWAR, no se realizará devolución del dinero y DEWAR se libera de responsabilidad de reprogramar el servicio. </li>
                <li>La factura se elaborará conforme a los datos relacionados en el RUT suministrados por el Cliente.</li>
                <li>La factura incluirá el valor por los servicios del proceso de inspección, más el valor de los gastos de viaje, necesarios para la prestación del servicio.</li>
                <li>El pago del servicio se debe realizar antes de la programación de la inspección por el valor total calculado en la cuenta de cobro que se envía previa facturación que se realizará al finalizar el servicio.</li>
                <li>El pago del servicio se debe realizar únicamente en la cuenta de ahorros de Bancolombia de DEWAR, cuya información se encuentra consignada en los documentos del proceso.</li>
                <li>El Cliente deberá enviar el soporte de pago a DEWAR para continuar con el proceso de programación.</li>
                <li>Una vez realizado el pago, si el Cliente desiste del proceso de inspección sin motivo de incumplimiento por parte DEWAR de las condiciones de esta propuesta, no se realizará devolución del dinero.</li>
            </ul>
            <h5>6. VALIDEZ DE LA PROPUESTA</h5>
            <p>La presente propuesta tiene una validez de quince (15) días calendario, en caso de efectuar el pago luego del término establecido, pueden aplicar modificaciones, por concepto de gastos de viaje y alojamiento del inspector en los casos en que se requiera.</p>
            <h5>7. TÉRMINOS GENERALES</h5>
            <ul>
                <li>DEWAR realiza el proceso de inspección de manera imparcial e independiente de entidades relacionadas dedicadas a la fabricación, suministro, instalación o mantenimiento de y/o revestimiento de tanques y/o instalación de tubería de los ítems a inspeccionar. Las decisiones que se tomen en los procesos de inspección corresponden específicamente a la verificación de cumplimiento de listas establecidas. </li>
                <li>DEWAR realiza los procesos de inspección de instalaciones y pruebas de hermeticidad bajo cumplimiento del principio de independencia e imparcialidad, por lo tanto, cuando un mismo Cliente recibe estos dos servicios en la misma instalación el manejo comercial y técnico será desarrollado para cada proceso especifico de manera independiente. </li>
                <li>DEWAR realiza sus servicios en cumplimiento de las medidas de seguridad de las personas y las instalaciones, en el momento que no se puedan garantizar las mismas, DEWAR se podrá abstener de presentar el servicio.</li>
                <li>DEWAR asegura la confidencialidad de la información recibida antes, durante y después del proceso de inspección, excepto que una autoridad competente requiera que se divulgue información considerada como confidencial, en cuyo caso se informará a la organización dueña o responsable de la instalación.</li>
                <li>DEWAR cuenta con un procedimiento para dar tratamiento quejas y apelaciones, el cual está disponible bajo solicitud. Así mismo, bajo solicitud le informaremos sobre demás procedimientos que hacen parte del proceso de inspección.</li>
                <li>DEWAR cómo requisito de la acreditación como organismo de inspección cuenta con una póliza de RCE a favor de terceros para cobertura de los riesgos asociados al proceso de inspección. </li>
                <li>Si durante la prueba en cumplimiento de los procedimientos establecidos, se llegasen a presentar afectaciones a los ítems inspeccionados y sus accesorios, atribuibles a su estado y/o incumplimiento de las especificaciones técnicas, no corresponderá responsabilidad alguna a DEWAR. </li>
                <li>Los horarios de atención en las oficinas de DEWAR son de 8:00 am a las 5:00 pm días hábiles de lunes a viernes.</li>
            </ul>
            <p>Agradecemos, solicitarnos la información que consideren necesaria para llevar a buen término el proceso de referencia a su más pronta conveniencia.</p>
            <br /><br />
            <p>Atentamente, </p>
            <p>
                <strong>Oscar Alberto Domínguez García</strong><br />
                Representante Legal<br />
                <strong>DEWAR S.A.S</strong>
            </p>
            <div className='text-center mt-30'>
                Carrera 61 # 96 A 23 Oficina 201 Barrio Andes, Bogotá – Colombia. Tel (601) 3001153 Cel. 3163702077<br />
                E-mail. comercial@dewar.com.co                                                   
            </div>
        </Container>
    )
}

const Container = styled.div`
	margin-bottom: 50px;
    padding: 10px 20px;
	p {
		font-size: 14px;
	}
	span.variable {
		color: #333;
		font-weight: 600;
	}
	h5 {
		font-size: 18px;
        margin-bottom: 15px;
	}
`