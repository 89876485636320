import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Divider, message, Popconfirm, Table, Tooltip } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInbox, faPaperPlane, faSearch, faTrash } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'

import EmailLogsModal from '../../EmailLogs/EmailLogsModal'

import { currency, handleError } from '../../../helpers'
import { deletePreInvoice, notifyPreInvoice } from '../services'

export default function PreInvoicesTable({ preInvoices, reload }) {
	const currentUser = useSelector(state => state.auth.user)
	const [emailLogsModal, setEmailLogsModal] = useState(null)

	const handleDelete = id => {
		deletePreInvoice(id)
			.then(res => {
				message.success(res.data.message)
				reload()
			})
			.catch(error => handleError(error))
	}

	const handlePreinvoiceNotify = id => {
		notifyPreInvoice(id)
			.then(res => {
				message.success(res.data.message)
				reload()
			})
			.catch(error => handleError(error))
	}
	
	const columns = [
		{
			title: 'ID',
			dataIndex: 'id',
			render: (t,r) => `CCN-0${t}-${moment(r.elaborated_at).format('MM')}-${moment(r.elaborated_at).format('YYYY')}`
		},
		{
			title: 'Fecha Elaboración',
			dataIndex: 'elaborated_at',
			render: t => moment(t).format('DD-MMM-YYYY').toUpperCase()
		},
		{
			title: 'Valor Servicio',
			dataIndex: 'service_value',
			render: (t, r) => `$ ${currency(t*r.quantity)} COP`
		},
		{
			title: 'Gastos Viaje',
			dataIndex: 'trip_expenses_value',
			render: t => t ? `$ ${currency(t)} COP` : <em className='small'>Sin especificar</em>
		},
		{
			title: 'Acciones',
			width: '130px',
			render: (t, r) => (
				<React.Fragment>
					<Tooltip title="Ver Cuenta de Cobro">
						<Link to={`/pre_invoices/preview/${r.id}`}>
							<FontAwesomeIcon className="text-link" icon={faSearch} />
						</Link>
					</Tooltip>
					<Divider type="vertical" />
					<Tooltip title="Enviar Notificación">
						<Popconfirm 
							title="¿Desea enviar notificación?"
							okText="Enviar"
							cancelText="Cancelar"
							onConfirm={() => handlePreinvoiceNotify(r.id)}
							okButtonProps={{ danger:true }}
						>
							<FontAwesomeIcon className="text-link" icon={faPaperPlane} />
						</Popconfirm>
					</Tooltip>
					<Divider type="vertical" />
					<Tooltip title="Correos Enviados">
						<div className='inline' onClick={() => setEmailLogsModal(r.id)}>
							<FontAwesomeIcon className="text-link" icon={faInbox} />
						</div>
					</Tooltip>
					{ currentUser.role === 'admin' && (
						<React.Fragment>
							<Divider type="vertical" />
							<Tooltip title="Eliminar Cuenta de Cobro">
								<Popconfirm 
									title="¿Desea eliminar cuenta de cobro?"
									okText="Eliminar"
									cancelText="Cancelar"
									onConfirm={() => handleDelete(r.id)}
									okButtonProps={{ danger:true }}
								>
									<FontAwesomeIcon className="text-link" icon={faTrash} />{" "}
								</Popconfirm>
							</Tooltip>
						</React.Fragment>
					)}
				</React.Fragment>
			)
		}
	]
	
	return (
		<React.Fragment>
			<Table 
				dataSource={preInvoices}
				columns={columns}
				rowKey={r => r.id}
				size='small'
			/>
			{ emailLogsModal && (
				<EmailLogsModal
					visible
					onClose={() => setEmailLogsModal(null)}
					modelType="pre_invoice"
					modelId={emailLogsModal}
				/>
			)}
		</React.Fragment>
	)
}