import dewarApi, { getDomain } from '../../services/dewarApi'

export const getVerificationLists = params => (
	new Promise((resolve, reject) => {
		dewarApi()
			.get('/verification_lists', { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const getVerificationList = (id, params) => (
	new Promise((resolve, reject) => {
		dewarApi()
			.get(`/verification_lists/${id}`, { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const publicGetVerificationList = (id, params) => (
	new Promise((resolve, reject) => {
		dewarApi()
			.get(`/public/verification_lists/${id}`, { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const storeVerificationList = data => (
	new Promise((resolve, reject) => {
		dewarApi()
			.post('/verification_lists', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const updateVerificationList = (id, data) => (
	new Promise((resolve, reject) => {
		dewarApi()
			.put(`/verification_lists/${id}`, data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const deleteVerificationList = id => (
	new Promise((resolve, reject) => {
		dewarApi()
			.delete(`/verification_lists/${id}`)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const searchVerificationLists = async (name, token) => (
	fetch( getDomain()+`/verification_lists?filter[name]=${name}`, {
		headers: {'Authorization': `Bearer ${token}`}
	})
		.then((response) => response.json())
		.then((res) =>
			res.data.map(verification_list => ({
				label: verification_list.name,
				value: verification_list.id,
			})),
		)
)