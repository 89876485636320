import React, { useState } from 'react'
import { Divider, Switch } from 'antd'
import { Button } from 'reactstrap'
import { Spinner } from 'react-bootstrap'
import html2canvas from 'html2canvas'
import { jsPDF } from 'jspdf'

export default function CertificateTopBar({ 
	certificateSku, isAdmin, hideBackground, setHideBackground, isOfficial, setIsOfficial 
}) {
	const [loading, setLoading] = useState(false)

	const printDocument = () => {
		setLoading(true)
		const input = document.getElementById('certificateDiv')
		html2canvas(input)
		  	.then((canvas) => {
				const imgData = canvas.toDataURL('image/png')
				
				const pdf = new jsPDF('p', 'mm', 'letter', true)

				var position = 0
				var pageHeight = pdf.internal.pageSize.getHeight()
				var imgWidth = pdf.internal.pageSize.getWidth()
				var imgHeight = canvas.height * imgWidth / canvas.width
				var heightLeft = imgHeight
				
				pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
				heightLeft -= pageHeight;
				
				while (heightLeft >= 0) {
					position = heightLeft - imgHeight;
					pdf.addPage('letter')
					pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight)
					heightLeft -= pageHeight
				}
			 	
			 	pdf.save(`Certificado ${certificateSku} - ${isOfficial ? 'Oficial' : 'Borrador'}.pdf`)
		  })
		  .then(() => setLoading(false))
		;
	}

	return (
		<React.Fragment>
			<div className='no-print card'>
				<div className='card-body'>
					{ isAdmin && (
						<React.Fragment>
							<Switch 
								checked={!hideBackground} 
								onChange={() => setHideBackground(!hideBackground)} 
							/>
							{" "}Fondo del Certificado
							<Divider type="vertical" />
							<Switch 
								checked={isOfficial} 
								onChange={() => setIsOfficial(!isOfficial)} 
							/>
							{" "}Certificado Oficial
							<Divider type="vertical" />
						</React.Fragment>
					)}
					<Button 
						color='primary' 
						outline 
						onClick={printDocument}
						disabled={loading}
					>
						{ loading && <Spinner animation='border' size="sm" className='mr-1' /> }
						Exportar PDF
					</Button>
				</div>
			</div>
		</React.Fragment>
	)
}