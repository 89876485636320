import React from 'react'
import { useHistory } from 'react-router'
import { Col, Form, InputGroup, Row } from 'react-bootstrap'
import { message } from 'antd'

import { BottomBar, SectionTitle, SectionContent } from '../../../../components/WizardComponents'

import { storeInquiry } from '../../services'
import { handleError } from '../../../../helpers'

export default function FinalDetailsStep({ form, setForm, currentStep, setCurrentStep }) {
	const history = useHistory()

	const handleBackStep = () => {
		setCurrentStep(currentStep-1)
	}

	const handleNextStep = () => {
		if(!form.client_requirements) return message.warning('Debe ingresar requisitos del cliente')
		if(!form.invoice_requirements) return message.warning('Debe ingresar lo requisitos para facturación')
		if(!form.legal_reference) return message.warning('Debe ingresar la norma aplicable')
		if(!form.observations) return message.warning('Debe todos los campos. En caso de no aplicar coloque N/A.')
		if(!form.sent_by) return message.warning('Debe ingresar el nombre de solicitante')
		if(!form.sent_at) return message.warning('Debe ingresar la fecha de envio')

		storeInquiry(form)
			.then(res => {
				message.success(res.data.message)
				history.push('/inquiries')
			})
			.catch(error => handleError(error))
	}

	return (
		<React.Fragment>
			<SectionTitle>REQUISITOS DEL CLIENTE</SectionTitle>
			<SectionContent>
				<p className="mb-10">Indique si para la realización de la inspección, el personal asignado DEWAR, debe cumplir requisitos como actividades de capacitación en seguridad o la presentación de alguna documentación y elementos de protección personal para el ingreso a las instalaciones a inspeccionar:</p>
				<Form.Control 
					as="textarea"
					value={form.client_requirements}
					onChange={e => setForm({ ...form, client_requirements: e.target.value })}
					placeholder='Escriba aquí...'
					style={{ height: '80px' }}
				/>
			</SectionContent>
			<SectionContent>
				<p>Indique si su empresa requiere de un trámite específico para realizar la radicación o cancelación de las facturas:</p>
				<Form.Control 
					as="textarea"
					value={form.invoice_requirements}
					onChange={e => setForm({ ...form, invoice_requirements: e.target.value })}
					placeholder='Escriba aquí...'
					style={{ height: '80px' }}
				/>	
			</SectionContent>
			<SectionTitle>Norma frente a las cuales se realiza el proceso de inspección.</SectionTitle>
			<SectionContent>
				<Form.Control 
					as="textarea"
					value={form.legal_reference}
					onChange={e => setForm({ ...form, legal_reference: e.target.value })}
					placeholder='Escriba aquí...'
					style={{ height: '130px' }}
				/>
			</SectionContent>
			<SectionTitle>INFORMACIÓN ADICIONAL ESPECÍFICA ÚTIL PARA LA PRESTACIÓN DEL SERVICIO:</SectionTitle>
			<SectionContent>
				<Form.Control 
					as="textarea"
					value={form.observations}
					onChange={e => setForm({ ...form, observations: e.target.value })}
					placeholder='Escriba aquí...'
					style={{ height: '80px' }}
				/>
				<Row>
					<InputGroup as={Col}>
						<InputGroup.Text>Nombre de quien envia la solicitud:</InputGroup.Text>
						<Form.Control 
							value={form.sent_by}
							onChange={e => setForm({ ...form, sent_by: e.target.value })}
							placeholder='Escriba aquí...'
						/>
					</InputGroup>
					<InputGroup as={Col}>
						<InputGroup.Text>Fecha de envío de la solicitud:</InputGroup.Text>
						<Form.Control 
							value={form.sent_at}
							onChange={e => setForm({ ...form, sent_at: e.target.value })}
							type="date"
						/>
					</InputGroup>
				</Row>
			</SectionContent>
			<BottomBar 
				handleNextStep={handleNextStep} 
				handleBackStep={handleBackStep} 
				nextBtnText="Guardar y Finalizar"
			/>
		</React.Fragment>
	)
}