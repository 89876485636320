import dewarApi, { getDomain } from '../../services/dewarApi'

export const getImpartialityNotices = params => (
	new Promise((resolve, reject) => {
		dewarApi()
			.get('/impartiality_notices', { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const getImpartialityNotice = (id, params) => (
	new Promise((resolve, reject) => {
		dewarApi()
			.get(`/impartiality_notices/${id}`, { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const storeImpartialityNotice = data => (
	new Promise((resolve, reject) => {
		dewarApi()
			.post('/impartiality_notices', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const updateImpartialityNotice = (id, data) => (
	new Promise((resolve, reject) => {
		dewarApi()
			.put(`/impartiality_notices/${id}`, data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const deleteImpartialityNotice = id => (
	new Promise((resolve, reject) => {
		dewarApi()
			.delete(`/impartiality_notices/${id}`)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const searchImpartialityNotices = async (name, token) => (
	fetch( getDomain()+`/impartiality_notices?filter[name]=${name}`, {
		headers: {'Authorization': `Bearer ${token}`}
	})
		.then((response) => response.json())
		.then((res) =>
			res.data.map(impartiality_notice => ({
				label: impartiality_notice.name,
				value: impartiality_notice.id,
			})),
		)
)