import React from 'react'
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { Form } from 'react-bootstrap'

export default function TaskTemplatesFiltersModal({ 
	visible, onClose, filters, setFilters, instalationTypeId, onSubmit 
}) {

	return (
		<React.Fragment>
			<Modal isOpen={visible}>
				<ModalHeader toggle={onClose}>Filtrar Tareas Pendientes</ModalHeader>
				<ModalBody>
					<Form.Group>
						<Form.Label>Tipo de Tanque</Form.Label>
						<Form.Control
							as="select"
							value={filters['scope']}
							onChange={e => setFilters({ 
								...filters, 
								'filter[except_type]': e.target.value ? `${instalationTypeId}:${e.target.value}` : null,
								scope: e.target.value,
								'filter[superficial_limit]': ''
							})}
						>
							<option value="">:: Sin filtro de tipo de tanque ::</option>
							<option value="Enterrados">Aplica para tanques superficiales</option>
							<option value="Superficiales">Aplica para tanques enterrados</option>
						</Form.Control>
					</Form.Group>
					{ filters['scope'] === 'Enterrados' && (
						<Form.Group>
							<Form.Label>Capacidad del Tanque</Form.Label>
							<Form.Control
								as="select"
								value={filters['filter[superficial_limit]']}
								onChange={e => setFilters({ 
									...filters, 
									'filter[superficial_limit]': e.target.value 
								})}
							>
								<option value="">:: Sin filtro de capacidad ::</option>
								<option value="Más">Más de 150.000 L</option>
								<option value="Menos">Menos de 150.000 L</option>
							</Form.Control>
						</Form.Group>
					)}
					<Form.Group>
						<Form.Label>Tipo de Instalaciones</Form.Label>
						<Form.Check 
							label="Antiguas"
							value="Antiguas"
							checked={filters['periods'].includes("Antiguas")}
							onChange={e => e.target.checked ? (
								setFilters({ ...filters, periods: [...filters['periods'], e.target.value] })
							) : (
								setFilters({ ...filters, periods: filters['periods'].filter(t => t !== e.target.value)})
							)}
						/>
						<Form.Check 
							label="Nuevas"
							value="Nuevas"
							checked={filters['periods'].includes("Nuevas")}
							onChange={e => e.target.checked ? (
								setFilters({ ...filters, periods: [...filters['periods'], e.target.value] })
							) : (
								setFilters({ ...filters, periods: filters['periods'].filter(t => t !== e.target.value)})
							)}
						/>
						<Form.Check 
							label="Modificadas o ampliadas - Almacenamiento"
							value="Modificadas o ampliadas - Almacenamiento"
							checked={filters['periods'].includes("Modificadas o ampliadas - Almacenamiento")}
							onChange={e => e.target.checked ? (
								setFilters({ ...filters, periods: [...filters['periods'], e.target.value] })
							) : (
								setFilters({ ...filters, periods: filters['periods'].filter(t => t !== e.target.value)})
							)}
						/>
						<Form.Check 
							label="Modificadas o ampliadas - Abastecimiento"
							value="Modificadas o ampliadas - Abastecimiento"
							checked={filters['periods'].includes("Modificadas o ampliadas - Abastecimiento")}
							onChange={e => e.target.checked ? (
								setFilters({ ...filters, periods: [...filters['periods'], e.target.value] })
							) : (
								setFilters({ ...filters, periods: filters['periods'].filter(t => t !== e.target.value)})
							)}
						/>
						<Form.Check 
							label="Todas"
							value="Todas"
							checked={filters['periods'].includes("Todas")}
							onChange={e => e.target.checked ? (
								setFilters({ ...filters, periods: [...filters['periods'], e.target.value] })
							) : (
								setFilters({ ...filters, periods: filters['periods'].filter(t => t !== e.target.value)})
							)}
						/>
					</Form.Group>
					<Form.Group>
						<Form.Label>Tipo de Tarea</Form.Label>
						<Form.Control
							as="select"
							value={filters['filter[task_type]']}
							onChange={e => setFilters({ 
								...filters, 
								'filter[task_type]': e.target.value
							})}
						>
							<option value="">:: Sin filtro de tipo de tarea ::</option>
							<option value="Documental">Solo Tareas Documentales</option>
							<option value="Técnica">Solo Tareas Técnicas</option>
						</Form.Control>
					</Form.Group>
				</ModalBody>
				<ModalFooter>
					<Button onClick={onSubmit} color="primary">
						Aplicar Filtros
					</Button>
				</ModalFooter>
			</Modal>
		</React.Fragment>
	)
}