import React from 'react'
import { useForm } from 'react-hook-form'
import { Form } from 'react-bootstrap'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { message } from 'antd'

import CompanyForm from './CompanyForm'

import { updateCompany } from '../services'
import { handleError } from '../../../helpers'

export default function EditCompanyModal({ visible, onClose, company, reload }) {
	const { register, handleSubmit, formState: { errors }, reset, watch } = useForm({
		defaultValues: {
			...company,
			is_autorretenedor: (company && company.is_autorretenedor) ? 1 : 0
		}
	})

	const onSubmit = values => {
		if(values.document === company.document) delete values.document
		delete values.portal_username
		updateCompany(company.id, values)
			.then(res => {
				message.success(res.data.message)
				reset()
				onClose()
				reload()
			})
			.catch(error => handleError(error))
	}	

	const handleClose = () => {
		reset()
		onClose()
	}

	return (
		<React.Fragment>
			<Modal isOpen={visible}>
				<ModalHeader toggle={handleClose}>Actualizar Empresa</ModalHeader>
				<ModalBody>
					<Form onSubmit={handleSubmit(onSubmit)}>
						<CompanyForm 
							company={company} 
							register={register} 
							errors={errors}
							watch={watch}
						/>
						<Button type="submit" color="primary">Actualizar</Button>
					</Form>
				</ModalBody>
			</Modal>
		</React.Fragment>
	)
}