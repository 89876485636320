import React from 'react'
import { Descriptions } from 'antd'

import { SectionContent, SectionTitle } from '../../../components/WizardComponents'

export default function InquiryFinalDetails({ inquiry }) {
	return (
		<React.Fragment>
			<SectionTitle>REQUISITOS DEL CLIENTE:</SectionTitle>
			<SectionContent>
				Indique si para la realización de la inspección, el personal asignado DEWAR, debe cumplir requisitos como actividades de capacitación en seguridad o la presentación de alguna documentación y elementos de protección personal para el ingreso a las instalaciones a inspeccionar.<br /><br />
				{inquiry.client_requirements ? inquiry.client_requirements : <em>Sin específicar</em>}
			</SectionContent>
			<SectionContent>
				Indique si su empresa requiere de un trámite específico para realizar la radicación o cancelación de las facturas:<br /><br />
				{inquiry.invoice_requirements ? inquiry.invoice_requirements : <em>Sin específicar</em>}
			</SectionContent>
			<SectionTitle>Norma frente a las cuales se realiza el proceso de inspección.</SectionTitle>
			<SectionContent>
				{inquiry.legal_reference}
			</SectionContent>
			<SectionTitle>AUTORIZACIÓN TRATAMIENTO DE DATOS PERSONALES:</SectionTitle>
			<SectionContent>Otorgo mi consentimiento a DEWAR SAS para tratar mi información personal, de acuerdo con la política de tratamiento de datos personales, en concordancia con la Ley 1581 de 2012, y el Decreto reglamentario 1377 de 2013</SectionContent>
			<SectionTitle>INFORMACIÓN ADICIONAL ESPECÍFICA ÚTIL PARA LA PRESTACIÓN DEL SERVICIO:</SectionTitle>
			<SectionContent>
				<Descriptions column={2} bordered>
					<Descriptions.Item span={2} label="Observaciones:">
						{inquiry.observation ? inquiry.observations : <em>Sin específicar</em>}
					</Descriptions.Item>
					<Descriptions.Item label="Nombre de quien envía la solicitud de servicio:">
						{inquiry.sent_by}
					</Descriptions.Item>
					<Descriptions.Item label="Fecha de envío de la solicitud de servicio:">
						{inquiry.sent_at}
					</Descriptions.Item>
				</Descriptions>
			</SectionContent>
		</React.Fragment>
	)
}