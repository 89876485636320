import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Divider, message, Popconfirm, Table, Tooltip } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faToolbox, faTrash, faUserLock } from '@fortawesome/free-solid-svg-icons'

import UserEquipmentsModal from '../../UserEquipments/UserEquipmentsModal'
import UserPasswordModal from './UserPasswordModal'
import EditUserModal from './EditUserModal'

import { deleteUser } from '../services'
import { handleError } from '../../../helpers'

export default function UsersTable({ users, reload }) {
	const currentUser = useSelector(state => state.auth.user)
	const [editUser, setEditUser] = useState(null)
	const [userEquipments, setUserEquipments] = useState(null)
	const [userPasswordModal, setUserPasswordModal] = useState(null)

	const handleDelete = id => {
		deleteUser(id)
			.then(() => {
				message.success('Usuario Eliminado Exitosamente')
				reload()
			})
			.catch(error => handleError(error))
	}

	const columns = [
		{
			title: 'Nombre Completo',
			dataIndex: 'name'
		},
		{
			title: 'Documento',
			dataIndex: 'document'
		},
		{
			title: 'Celular',
			dataIndex: 'mobile'
		},
		{
			title: 'Cargo',
			dataIndex: 'role_name'
		},
		{
			title: 'Acciones',
			render: (t, record) => (
				<React.Fragment>
					<Tooltip title="Editar Usuario">
						<div className='inline' onClick={()=>setEditUser(record)}>
							<FontAwesomeIcon className="text-link" icon={faEdit} />
						</div>
					</Tooltip>
					<Divider type="vertical" />
					<Tooltip title="Cambiar Contraseña">
						<div className='inline' onClick={()=>setUserPasswordModal(record)}>
							<FontAwesomeIcon className="text-link" icon={faUserLock} />
						</div>
					</Tooltip>
					<Divider type="vertical" />
					<Tooltip title="Equipo de Medición">
						<div className='inline' onClick={()=>setUserEquipments(record)}>
							<FontAwesomeIcon className="text-link" icon={faToolbox} />
						</div>
					</Tooltip>
					{ record.id !== currentUser.id && currentUser.role === 'admin' && (
						<React.Fragment>
							<Divider type="vertical" />
							<Popconfirm 
								title="¿Desea eliminar el usuario?"
								okText="Eliminar"
								cancelText="Cancelar"
								onConfirm={() => handleDelete(record.id)}
								okButtonProps={{ danger:true }}
							>
								<FontAwesomeIcon className="text-link" icon={faTrash} />
							</Popconfirm>
						</React.Fragment>
					)}
				</React.Fragment>
			)
		}
	]

	return (
		<React.Fragment>
			<Table 
				dataSource={users}
				columns={columns}
				rowKey={record => record.id}
			/>
			{ editUser && (
				<EditUserModal 
					visible={true}
					onClose={() => setEditUser(null)}
					user={editUser}
					reload={reload}
				/>
			)}
			{ userEquipments && (
				<UserEquipmentsModal 
					visible
					onClose={() => setUserEquipments(null)}
					user={userEquipments}
				/>
			)}
			{ userPasswordModal && (
				<UserPasswordModal 
					visible
					onClose={() => setUserPasswordModal(null)}
					user={userPasswordModal}
				/>
			)}
		</React.Fragment>
	)
}